<template>
  <div class="trip-info">
    <div class="pl-lg-9 px-sm-7 py-sm-5 py-3 px-3 d-flex flex-wrap item-content">
      <div class="check" v-if="role != 'trip_organizer'">
        <v-checkbox class="py-2 mt-0 mb-2" color="success" @change="updateFeatured" v-model="data.featured" hide-details>
          <template v-slot:label>
            <div class="ms-2">
              <div class="black--text font-weight-bold">Featured</div>
              <div class="text-body-2 text--text">Mark Trip as Featured to show it on the Featured trip section</div>
            </div>
          </template>
        </v-checkbox>
      </div>
      <div class="check" v-if="role != 'trip_organizer'">
        <v-checkbox class="py-2 mt-0 mb-2" color="success" @change="updateSpecial" v-model="data.special_offer" hide-details>
          <template v-slot:label>
            <div class="ms-2">
              <div class="black--text font-weight-bold">Special offer</div>
              <div class="text-body-2 text--text">
                Mark Trip as a Special offer to enable the 'Special offer' label on the Trip card and Trip view page
              </div>
            </div>
          </template>
        </v-checkbox>
      </div>
      <div>
        <div class="font-weight-bold mb-2">Status</div>
        <v-btn-toggle class="group-btn me-10" v-model="data.is_hidden" @change="updateHidden">
          <v-btn :disabled="role == 'trip_organizer'" class="text-body-2" :value="false" outlined height="36">Visible</v-btn>
          <v-btn :disabled="role == 'trip_organizer'" class="text-body-2" :value="true" outlined height="36">Hidden</v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <v-divider class="mt-2"></v-divider>
    <div class="pl-lg-9 px-sm-7 pt-sm-7 pt-3 px-3 item-content">
      <div class="text-h6 font-weight-bold">General information</div>
      <div class="text--text mb-1">Provide general information about trip. Some information is necessary.</div>
      <div class="font-weight-bold mb-3">Trip type</div>
      <v-select
        class="field46"
        v-model="data.type"
        placeholder="Select type"
        :error-messages="typeErrors"
        :items="itemsTypes"
        item-text="name_en"
        item-value="id"
        return-object
        outlined
        dense
        required
        color="primary"
        height="46"
        :readonly="role == 'trip_organizer'"
      ></v-select>
      <div class="font-weight-bold mb-3">Trip subtype</div>
      <v-select
        class="field46"
        v-model="data.subtype"
        placeholder="Select subtype"
        :error-messages="subtypeErrors"
        :items="itemsSubtypes"
        item-text="title"
        item-value="name"
        outlined
        dense
        required
        color="primary"
        height="46"
        :readonly="role == 'trip_organizer'"
      ></v-select>
      <div class="font-weight-bold mb-3">Locality</div>
      <v-select
        class="field46"
        v-model="data.locality"
        placeholder="Select locality"
        :error-messages="localityErrors"
        :items="itemsLocality"
        item-text="title"
        item-value="name"
        outlined
        dense
        required
        color="primary"
        height="46"
        :readonly="role == 'trip_organizer'"
      ></v-select>
      <div class="font-weight-bold mb-3">Category</div>
      <v-select
        class="field46"
        v-model="data.category"
        placeholder="Select category"
        :error-messages="categoryErrors"
        :items="itemsCategory"
        item-text="title_en"
        item-value="id"
        return-object
        outlined
        dense
        required
        color="primary"
        height="46"
        :readonly="role == 'trip_organizer'"
      ></v-select>
      <div class="font-weight-bold mb-3">Trip Organizer</div>
      <v-select
        class="field46"
        v-model="data.organizer"
        placeholder="Select organizer"
        :error-messages="orgErrors"
        :items="itemsOrganiz"
        item-text="organizer.name"
        item-value="organizer.id"
        return-object
        outlined
        dense
        required
        color="primary"
        height="46"
        :readonly="role == 'trip_organizer'"
      ></v-select>
      <div class="font-weight-bold mb-3">Departure country/city</div>
      <div class="d-flex flex-md-row flex-column">
        <v-autocomplete
          v-if="data.departure"
          class="field46 me-md-3"
          v-model="data.departure.country"
          @change="getCity"
          placeholder="Country"
          :items="countryList"
          item-text="name"
          item-value="id"
          return-object
          outlined
          dense
          required
          color="primary"
          height="46"
          :readonly="role == 'trip_organizer'"
        ></v-autocomplete>
        <v-autocomplete
          v-else
          class="field46 me-md-3"
          v-model="departure_country"
          @change="getCity"
          placeholder="Country"
          :items="countryList"
          item-text="name"
          item-value="id"
          return-object
          outlined
          dense
          required
          color="primary"
          height="46"
          :readonly="role == 'trip_organizer'"
        ></v-autocomplete>
        <v-autocomplete
          v-if="data.departure"
          class="field46 mt-sm-0 mt-n2 ms-md-3"
          v-model="data.departure.city"
          placeholder="City"
          :error-messages="depCityErrors"
          :items="cityList"
          item-text="name"
          item-value="id"
          return-object
          outlined
          dense
          required
          color="primary"
          height="46"
          :readonly="!data.departure.country && role == 'trip_organizer'"
        ></v-autocomplete>
        <v-autocomplete
          v-else
          class="field46 mt-sm-0 mt-n2 ms-md-3"
          v-model="departure_city"
          placeholder="City"
          :error-messages="depCityErrors"
          :items="cityList"
          item-text="name"
          item-value="id"
          return-object
          outlined
          dense
          required
          color="primary"
          height="46"
          :readonly="!departure_country && role == 'trip_organizer'"
        ></v-autocomplete>
      </div>
      <v-textarea
        class="mt-n2"
        v-model="data.departure_address"
        placeholder="Address"
        :error-messages="depAddressErrors"
        auto-grow
        rows="3"
        row-height="30"
        outlined
        dense
        required
        color="primary"
        :readonly="role == 'trip_organizer'"
      ></v-textarea>
      <div class="font-weight-bold mb-3">Destination country/city</div>
      <draggable :list="data.destinations" @change="changeOrder($event)" handle=".handle">
        <div v-for="(item, i) in data.destinations" :key="i">
          <div class="d-flex flex-md-row flex-column align-md-center" style="max-width: 766px" v-if="i < 10">
            <div class="d-flex align-center width100 v-input me-md-3">
              <v-card
                width="20"
                min-width="20"
                height="20"
                flat
                v-if="data.destinations.length - 1 != i && role != 'trip_organizer'"
                class="d-flex align-center justify-center handle move-icon me-2 mb-6"
              >
                <img class="d-block" src="@/assets/icon/move.svg" alt="move" />
              </v-card>
              <v-autocomplete
                class="field46"
                v-model="item.destination.country"
                @change="getCityTwo(item.destination.country.id, i + 1)"
                placeholder="Country"
                :items="countryList"
                item-text="name"
                item-value="id"
                return-object
                outlined
                dense
                required
                color="primary"
                height="46"
                :readonly="role == 'trip_organizer'"
              ></v-autocomplete>
            </div>
            <div class="d-flex align-center width100 v-input ms-md-3">
              <v-btn
                v-if="data.destinations.length - 1 != i && role != 'trip_organizer'"
                class="d-md-none me-2 mb-7"
                @click="removeItem(item, i)"
                x-small
                icon
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-autocomplete
                class="field46 mt-sm-0 mt-n2"
                v-model="item.destination.city"
                placeholder="City"
                :error-messages="desCityErrors"
                @change="
                  i + 1 == data.destinations.length
                    ? (data.destinations.push({ destination: { city: {}, country: {} } }), addDestinations(item, i))
                    : updateDestinations(item, i)
                "
                :items="citysList[serchArray(citysList, item.destination.city) || citysList.length - 1]"
                item-text="name"
                item-value="id"
                ref="city"
                return-object
                outlined
                dense
                required
                color="primary"
                height="46"
                :readonly="!item.destination.country.id && role != 'trip_organizer'"
              ></v-autocomplete>
              <v-btn
                v-if="data.destinations.length - 1 != i && role != 'trip_organizer'"
                class="d-md-block d-none mb-6 ms-2"
                @click="openModal(item, i)"
                x-small
                icon
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </draggable>
      <div class="font-weight-bold mb-3">Availability dates</div>
      <div class="d-flex flex-md-row flex-column">
        <vc-date-picker
          locale="en"
          :input-debounce="500"
          v-model="data.available_since"
          :masks="masks"
          :model-config="modelConfig"
          :first-day-of-week="2"
          is-required
          color="blue"
          class="v-input width100 me-md-3"
          :disabled="role == 'trip_organizer'"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <v-text-field
              class="field46"
              :value="inputValue"
              v-on="inputEvents"
              placeholder="From"
              :error-messages="fromErrors"
              outlined
              dense
              color="primary"
              height="46"
              :disabled="role == 'trip_organizer'"
            >
              <template v-slot:append>
                <v-img class="mt-2" src="@/assets/icon/calendar.svg"></v-img>
              </template>
            </v-text-field>
          </template>
        </vc-date-picker>
        <vc-date-picker
          locale="en"
          :input-debounce="500"
          v-model="data.available_till"
          :masks="masks"
          :model-config="modelConfig"
          :first-day-of-week="2"
          is-required
          color="blue"
          class="v-input width100 mt-sm-0 mt-n2 ms-md-3"
          :disabled="role == 'trip_organizer'"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <v-text-field
              class="field46"
              :value="inputValue"
              v-on="inputEvents"
              placeholder="To"
              :error-messages="toErrors"
              outlined
              dense
              color="primary"
              height="46"
              :disabled="role == 'trip_organizer'"
            >
              <template v-slot:append>
                <v-img class="mt-2" src="@/assets/icon/calendar.svg"></v-img>
              </template>
            </v-text-field>
          </template>
        </vc-date-picker>
      </div>
    </div>
    <v-divider class="mt-2" v-if="role != 'trip_organizer'"></v-divider>
    <div class="text-end py-3 px-sm-10 px-3" v-if="role != 'trip_organizer'">
      <v-btn @click="updateItem" depressed color="primary" large width="136">Save</v-btn>
    </div>
    <v-divider v-if="role != 'trip_organizer'"></v-divider>
    <div class="pl-lg-9 pa-sm-7 pa-3 item-content" v-if="role != 'trip_organizer'">
      <div class="text-h6 font-weight-bold">Loyalty programs</div>
      <div class="text--text">Select the programs that customer can apply during the booking process.</div>
      <div class="d-flex flex-wrap align-center px-2 py-4">
        <div>Discount code</div>
        <v-spacer></v-spacer>
        <v-btn-toggle class="group-btn" v-model="data.discount_code_allowed" @change="updateLoyalty">
          <v-btn class="text-body-2" value="enabled" outlined height="36">Available</v-btn>
          <v-btn class="text-body-2" value="disabled" outlined height="36">Unavailable</v-btn>
          <v-btn class="text-body-2" value="default" outlined height="36">Default for Type</v-btn>
        </v-btn-toggle>
      </div>
      <v-divider></v-divider>
      <div class="d-flex flex-wrap align-center px-2 py-4">
        <div>Mokafaa</div>
        <v-spacer></v-spacer>
        <v-btn-toggle class="group-btn" v-model="data.mokafaa_allowed" @change="updateLoyalty">
          <v-btn class="text-body-2" value="enabled" outlined height="36">Available</v-btn>
          <v-btn class="text-body-2" value="disabled" outlined height="36">Unavailable</v-btn>
          <v-btn class="text-body-2" value="default" outlined height="36">Default for Type</v-btn>
        </v-btn-toggle>
      </div>
      <v-divider></v-divider>
      <div class="d-flex flex-wrap align-center px-2 py-4">
        <div>ANB</div>
        <v-spacer></v-spacer>
        <v-btn-toggle class="group-btn" v-model="data.anb_allowed" @change="updateLoyalty">
          <v-btn class="text-body-2" value="enabled" outlined height="36">Available</v-btn>
          <v-btn class="text-body-2" value="disabled" outlined height="36">Unavailable</v-btn>
          <v-btn class="text-body-2" value="default" outlined height="36">Default for Type</v-btn>
        </v-btn-toggle>
      </div>
      <v-divider></v-divider>
      <div class="d-flex flex-wrap align-center px-2 py-4">
        <div>Digital Wallet</div>
        <v-spacer></v-spacer>
        <v-btn-toggle class="group-btn" v-model="data.dw_allowed" @change="updateLoyalty">
          <v-btn class="text-body-2" value="enabled" outlined height="36">Available</v-btn>
          <v-btn class="text-body-2" value="disabled" outlined height="36">Unavailable</v-btn>
          <v-btn class="text-body-2" value="default" outlined height="36">Default for Type</v-btn>
        </v-btn-toggle>
      </div>
      <v-divider></v-divider>
      <div class="d-flex flex-wrap align-center px-2 py-4">
        <div>Tabby</div>
        <v-spacer></v-spacer>
        <v-btn-toggle class="group-btn" v-model="data.tabby_allowed" @change="updateLoyalty">
          <v-btn class="text-body-2" value="enabled" outlined height="36">Available</v-btn>
          <v-btn class="text-body-2" value="disabled" outlined height="36">Unavailable</v-btn>
          <v-btn class="text-body-2" value="default" outlined height="36">Default for Type</v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <v-divider v-if="role != 'trip_organizer'"></v-divider>
    <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
      <v-card v-if="data.template && role != 'trip_organizer'" flat class="py-6 px-5 primary-card mb-4">
        <div class="text-h6 font-weight-bold mb-2">This is a template trip</div>
        <div class="mb-5 text--text">
          The template trip is used as a base Trips, linked to Customer Trip requests. The platform can have a single template trip only.
          The template trips is not visible to customers
        </div>
        <v-btn @click="updateTemplate" depressed class="rounded" color="primary" width="206" height="30">
          <span class="text-body-2">Transform to a regular trip</span>
        </v-btn>
      </v-card>
      <v-card v-else-if="role != 'trip_organizer'" flat class="py-6 px-5 primary-card mb-4">
        <div class="text-h6 font-weight-bold mb-2">Transform this trip to a template</div>
        <div class="mb-5 text--text">
          The template trip is used as a base Trips, linked to Customer Trip requests. The platform can have a single template trip only.
          The template trip is not available to customers
        </div>
        <v-btn @click="updateTemplate" depressed class="rounded" color="primary" width="186" height="30">
          <span class="text-body-2">Transform to template</span>
        </v-btn>
      </v-card>
      <v-card flat class="py-6 px-5 remove-card" v-if="role != 'trip_organizer'">
        <div class="error--text text-h6 font-weight-bold mb-2">Remove trip</div>
        <div class="mb-1 text--text">Removing this trip will delete all information and attachments.</div>
        <div class="mb-7 text--text">Trip cannot be restored!</div>
        <v-btn @click="removeModal = true" depressed class="rounded" color="error" width="186" height="30">
          <span class="text-body-2">Remove trip</span>
        </v-btn>
      </v-card>
    </div>
    <v-dialog v-model="modal" width="480">
      <v-card class="pa-4">
        <div class="text-h6 font-weight-bold mb-3">
          Do you really want to remove Trip destination? All related Accommodations in Plans will be removed!
        </div>
        <div class="text-end">
          <v-btn @click="modal = false" outlined class="rounded" color="gray lighten-1" width="106" height="30">
            <span class="text-body-2 black--text">Cancel</span>
          </v-btn>
          <v-btn @click="removeItem" depressed class="error rounded ms-3" width="106" height="30">
            <span class="text-body-2">Remove</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="removeModal" max-width="480">
      <v-card flat class="pa-4">
        <div class="text-h6 font-weight-bold mb-3">Do you really want to delete this trip?</div>
        <div class="text--text text-body-2 mb-5">The information cannot be restored!</div>
        <div class="text-end">
          <v-btn @click="removeModal = false" depressed outlined class="rounded me-3" color="gray" width="106" height="30">
            <span class="text-body-2 black--text">Cancel</span>
          </v-btn>
          <v-btn @click="removeTrip" depressed class="rounded" color="error" width="106" height="30">
            <span class="text-body-2">Remove</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
  },
  data() {
    return {
      modal: false,
      removeModal: false,
      modalData: {},
      idx: null,
      departure_country: '',
      departure_city: '',
      masks: {
        input: 'MMM D, YYYY',
      },
      modelConfig: {
        type: 'number',
        mask: 'iso',
      },
      itemsSubtypes: [
        { name: 'regular', title: 'Regular' },
        { name: 'private', title: 'Private' },
        { name: 'surprise', title: 'Surprise' },
      ],
      itemsLocality: [
        { name: 'local', title: 'Local' },
        { name: 'international', title: 'International' },
      ],
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'saved') {
        this.$notify({
          title: 'Done!',
          message: 'Trip saved successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Trip updated successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'remove') {
        this.$notify({
          title: 'Done!',
          message: 'Destination trip item removed successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    openModal(i, j) {
      this.idx = j;
      this.modalData = i;
      this.modal = true;
    },
    serchArray(arr, item) {
      for (var i = 0, L = arr.length; i < L; i++) {
        const j = arr[i].findIndex((k) => k.id == item.id);
        if (j >= 0) {
          return i;
        }
      }
      return 0;
    },
    async changeOrder(e) {
      const data = new Object();
      data.order = e.moved.newIndex;
      await this.$store.dispatch('updateTripDestinationsOrder', { id: e.moved.element.id, data: data });
    },
    async addDestinations(i, j) {
      const data = new Object();
      data.city = i.destination.city.id;
      await this.$store.dispatch('addTripDestinations', data).then(() => {
        this.$refs.city[j].blur();
        this.notifi('update');
      });
    },
    async updateDestinations(i, j) {
      const data = new Object();
      data.city = i.destination.city.id;
      await this.$store.dispatch('updateTripDestinations', { id: i.id, data: data }).then(() => {
        this.$refs.city[j].blur();
        this.notifi('update');
      });
    },
    async removeItem() {
      await this.$store.dispatch('removeTripDestinations', this.modalData.id).then(() => {
        this.notifi('remove');
        this.modal = false;
        this.data.destinations.splice(this.idx, 1);
        this.citysList.splice(this.serchArray(this.citysList, this.modalData.destination.city), 1);
        this.idx = null;
      });
    },
    async getCity() {
      await this.$store.dispatch('getCityList', this.departure_country ? this.departure_country.id : this.data.departure.country.id);
    },
    async getCityTwo(i, idx) {
      this.citysList.splice(idx, 1);
      this.idx = idx;
      await this.$store.dispatch('getCityListTwo', i, idx);
    },
    async updateHidden() {
      await this.$store.dispatch('hiddenTripsItem', { is_hidden: this.data.is_hidden }).then(() => {
        this.notifi('update');
      });
    },
    async updateTemplate() {
      await this.$store.dispatch('templateTripsItem', { template: !this.data.template }).then(() => {
        this.notifi('update');
      });
    },
    async updateFeatured() {
      await this.$store.dispatch('featuredTripsItem', { featured: this.data.featured }).then(() => {
        this.notifi('update');
      });
    },
    async updateSpecial() {
      await this.$store.dispatch('specialTripsItem', { special_offer: this.data.special_offer }).then(() => {
        this.notifi('update');
      });
    },
    async removeTrip() {
      await this.$store.dispatch('removeTripsItem');
    },
    async updateLoyalty() {
      const data = new Object();
      data.discount_code_allowed = this.data.discount_code_allowed;
      data.mokafaa_allowed = this.data.mokafaa_allowed;
      data.dw_allowed = this.data.dw_allowed;
      data.anb_allowed = this.data.anb_allowed;
      data.tabby_allowed = this.data.tabby_allowed;
      await this.$store.dispatch('loyaltyTripsItem', data).then(() => {
        this.notifi('update');
      });
    },
    async updateItem() {
      this.error = [];
      const data = new Object();
      this.data.organizer ? (data.organizer = this.data.organizer.id) : '';
      this.data.type ? (data.type = this.data.type.id) : '';
      data.subtype = this.data.subtype;
      data.locality = this.data.locality;
      if (this.data.departure) {
        this.data.departure.city ? (data.departure_city = this.data.departure.city.id) : '';
      }
      this.departure_city ? (data.departure_city = this.departure_city.id) : '';
      data.departure_address = this.data.departure_address;
      // this.data.destination_city ? (data.destination_city = this.data.destination_city.id) : '';
      // if (this.data.destinations.length) {
      //   const array = [];
      //   for (let i = 0; i < this.data.destinations.length; i++) {
      //     array.push(this.data.destinations[i].city.id);
      //   }
      //   data.destinations = array;
      // }
      this.data.category ? (data.category = this.data.category.id) : '';
      data.available_since = this.data.available_since;
      data.available_till = this.data.available_till;
      data.title_en = this.data.title_en;
      data.title_ar = this.data.title_ar;
      data.short_description_en = this.data.short_description_en;
      data.short_description_ar = this.data.short_description_ar;
      data.description_en = this.data.description_en;
      data.description_ar = this.data.description_ar;
      this.data.vat_option ? (data.vat_option = this.data.vat_option.id) : '';
      data.vat_included = this.data.vat_included;
      this.data.service_fee_option ? (data.service_fee_option = this.data.service_fee_option.id) : '';
      data.service_fee_included = this.data.service_fee_included;
      await this.$store
        .dispatch('updateTripsItem', data)
        .then(() => {
          this.notifi('saved');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  computed: {
    data() {
      return this.$store.getters.trips;
    },
    countryList() {
      return this.$store.getters.countryList;
    },
    citysList() {
      return this.$store.getters.citysList;
    },
    cityList() {
      return this.$store.getters.cityList;
    },
    cityListTwo() {
      return this.$store.getters.cityListTwo;
    },
    itemsTypes() {
      return this.$store.getters.tripTypes;
    },
    itemsOrganiz() {
      return this.$store.getters.tripOrganiz;
    },
    itemsCategory() {
      return this.$store.getters.tripCategory;
    },
    role() {
      return this.$store.getters.profile.role;
    },
    typeErrors() {
      const errors = [];
      this.error.find((item) => item == 'type__required') && errors.push('Please select trip type');
      this.error.find((item) => item == 'type__invalid') && errors.push('Selected trip type is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    subtypeErrors() {
      const errors = [];
      this.error.find((item) => item == 'subtype__required') && errors.push('Please select trip subtype');
      this.error.find((item) => item == 'subtype__invalid') && errors.push('Selected trip subtype is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    localityErrors() {
      const errors = [];
      this.error.find((item) => item == 'locality__required') && errors.push('Please select locality');
      this.error.find((item) => item == 'locality__invalid') && errors.push('Selected locality is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    categoryErrors() {
      const errors = [];
      this.error.find((item) => item == 'category__required') && errors.push('Please select category');
      this.error.find((item) => item == 'category__invalid') && errors.push('Selected category is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    orgErrors() {
      const errors = [];
      this.error.find((item) => item == 'organizer__required') && errors.push('Please select trip organizer');
      this.error.find((item) => item == 'organizer__invalid') && errors.push('Selected trip organizer is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    depCityErrors() {
      const errors = [];
      this.error.find((item) => item == 'departure_city__required') && errors.push('Please enter departure city');
      this.error.find((item) => item == 'departure_city__invalid') && errors.push('Provided departure city is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    depAddressErrors() {
      const errors = [];
      this.error.find((item) => item == 'departure_address__required') && errors.push('Please enter departure address');
      this.error.find((item) => item == 'departure_address__invalid') && errors.push('Provided departure address is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desCityErrors() {
      const errors = [];
      this.error.find((item) => item == 'city__required') && errors.push('Please enter destination city');
      this.error.find((item) => item == 'city__invalid') && errors.push('Provided destination city is not valid');
      this.error.find((item) => item == 'destinations__limit_exceeded') &&
        errors.push('Destination cities count is too big (up to 10 destinations allowed)');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    fromErrors() {
      const errors = [];
      this.error.find((item) => item == 'available_since__required') && errors.push('Please enter available since');
      this.error.find((item) => item == 'available_since__invalid') && errors.push('Provided available since is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    toErrors() {
      const errors = [];
      this.error.find((item) => item == 'available_till__required') && errors.push('Please enter available till');
      this.error.find((item) => item == 'available_till__invalid') && errors.push('Provided available till is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
  },
};
</script>

<style lang="scss">
#app .trip-info {
  .v-input {
    max-width: 371px;
  }
  .primary-card {
    background: var(--v-primary-lighten3);
    border: 1px solid var(--v-gray-lighten2);
    border-radius: 6px !important;
  }
  .remove-card {
    background: var(--v-gray-lighten4);
    border: 1px solid var(--v-gray-lighten2);
    border-radius: 6px !important;
  }
  .check {
    .v-input__slot {
      align-items: initial;
    }
  }
  @media screen and (max-width: 700px) {
    .v-input {
      max-width: initial !important;
    }
    .group-btn {
      display: grid;
      button {
        border-width: thin !important;
        border-radius: 4px !important;
      }
    }
  }
}
</style>
