<template>
  <div class="trip-option">
    <v-card tile flat min-height="52" class="d-flex flex-wrap align-center" :class="!data.options.length ? 'ps-3' : ''">
      <v-btn
        depressed
        :style="`box-shadow: ${day == i ? '4px -2px 4px rgba(0, 0, 0, 0.08)' : '1px -1px 4px rgba(0, 0, 0, 0.04)'}; z-index: ${
          data.options.length - i
        }`"
        :color="day == i ? 'white' : 'gray lighten-3 text--text'"
        class="rounded-b-0 rounded-tl-0 px-7 me-n2"
        :height="52"
        @click="day = i"
        v-for="(j, i) in data.options"
        :key="i"
      >
        Option {{ i + 1 }}
      </v-btn>
      <v-btn
        v-if="!data.options.find((i) => i.id == undefined) && role != 'trip_organizer'"
        @click="addDay"
        icon
        class="me-4 ms-6 my-4 add-btn link"
      >
        <v-icon color="gray lighten-1">mdi-plus</v-icon>
      </v-btn>
    </v-card>
    <v-divider></v-divider>
    <v-card tile flat height="62vh" class="d-flex justify-center text-center align-center" v-if="!data.options.length">
      <div>
        <v-img contain height="150" src="@/assets/img/wait.svg"></v-img>
        <div class="text--text my-4">There is no option added yet</div>
        <v-btn
          v-if="role != 'trip_organizer'"
          @click="addDay"
          depressed
          width="106"
          height="30"
          color="primary"
          class="text-body-2 rounded"
        >
          Add option
        </v-btn>
      </div>
    </v-card>
    <div v-if="data.options.length" class="d-flex flex-column justify-space-between" style="min-height: 64vh">
      <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
        <div class="text-h6 font-weight-bold">Description</div>
        <div class="text--text mb-3">Provide the information in English and Arabic</div>
        <div class="d-flex flex-md-row flex-column multi-direct">
          <v-text-field
            class="field46 width100 me-md-3"
            v-model="data.options[day].title_en"
            placeholder="Title"
            :error-messages="titleEnErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
            :readonly="role == 'trip_organizer'"
          ></v-text-field>
          <v-text-field
            class="field46 width100 mt-md-0 mt-n2 ms-md-3"
            v-model="data.options[day].title_ar"
            placeholder="عنوان"
            :error-messages="titleArErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
            :readonly="role == 'trip_organizer'"
          ></v-text-field>
        </div>
        <div class="d-flex flex-md-row flex-column mt-n2 multi-direct">
          <v-textarea
            class="width100 me-md-3"
            v-model="data.options[day].description_en"
            placeholder="Description"
            :error-messages="desEnErrors"
            auto-grow
            rows="4"
            row-height="30"
            outlined
            dense
            required
            color="primary"
            :readonly="role == 'trip_organizer'"
          ></v-textarea>
          <v-textarea
            class="width100 mt-md-0 mt-n2 ms-md-3"
            v-model="data.options[day].description_ar"
            placeholder="وصف كامل"
            :error-messages="desArErrors"
            auto-grow
            rows="4"
            row-height="30"
            outlined
            dense
            required
            color="primary"
            :readonly="role == 'trip_organizer'"
          ></v-textarea>
        </div>
        <!-- <div class="text-h6 font-weight-bold">Seats</div>
        <div class="text--text mb-3">Provide the number of additional seats</div>
        <v-text-field
          class="field46"
          v-model="data.options[day].seats"
          placeholder="Price"
          suffix="SAR"
          :error-messages="priceErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field> -->
        <div class="font-weight-bold">Price</div>
        <div class="text--text mb-3">Provide the price of the option</div>
        <v-text-field
          class="field46"
          style="max-width: 160px"
          v-model="data.options[day].priceN"
          placeholder="Price"
          suffix="SAR"
          :error-messages="priceErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
          :readonly="role == 'trip_organizer'"
        ></v-text-field>
      </div>
      <div v-if="role != 'trip_organizer'">
        <v-divider></v-divider>
        <div class="pl-lg-9 px-sm-7 px-3 py-3 item-content d-flex flex-wrap">
          <v-btn @click="removeDay" width="126" height="30" outlined class="rounded" color="gray">
            <span class="error--text text-body-2">Remove option</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="saveDay" depressed color="primary" class="text-body-2 rounded" width="106" height="30">Save</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      day: 0,
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'saved') {
        this.$notify({
          title: 'Done!',
          message: 'Option added successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Option updated successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'remove') {
        this.$notify({
          title: 'Done!',
          message: 'Option removed',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    addDay() {
      this.data.options.push({});
      this.day = this.data.options.length - 1;
    },
    saveDay() {
      this.error = [];
      const dataRequest = this.data.options[this.day];
      const data = new Object();
      data.title_en = dataRequest.title_en;
      data.title_ar = dataRequest.title_ar;
      data.description_en = dataRequest.description_en;
      data.description_ar = dataRequest.description_ar;
      data.price = +dataRequest.priceN * 100;
      if (!dataRequest.id) {
        this.$store
          .dispatch('addOptionTripsItem', data)
          .then(() => {
            this.notifi('saved');
          })
          .catch((e) => {
            this.error = e.response.data.error;
          });
      } else {
        this.$store
          .dispatch('updateOptionTripsItem', { id: dataRequest.id, data: data })
          .then(() => {
            this.notifi('update');
          })
          .catch((e) => {
            this.error = e.response.data.error;
          });
      }
    },
    async removeDay() {
      const i = this.day;
      this.day == 0 ? (this.day = 0) : this.day--;
      if (this.data.options[i].id) {
        await this.$store.dispatch('removeOptionTripsItem', this.data.options[i].id).then(() => {
          this.data.options.splice(i, 1);
        });
      } else {
        this.data.options.splice(i, 1);
      }
      this.notifi('remove');
    },
  },
  watch: {
    day() {
      this.error = [];
    },
  },
  computed: {
    data() {
      return this.$store.getters.trips;
    },
    role() {
      return this.$store.getters.profile.role;
    },
    titleEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_en__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_en__invalid') && errors.push('Provided title is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    titleArErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_ar__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_ar__invalid') && errors.push('Provided title is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_en__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_en__invalid') && errors.push('Provided description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desArErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_ar__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_ar__invalid') && errors.push('Provided description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    priceErrors() {
      const errors = [];
      this.error.find((item) => item == 'price__required') && errors.push('Please enter price');
      this.error.find((item) => item == 'price__invalid') && errors.push('Provided price is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
  },
};
</script>

<style lang="scss">
.trip-option {
  .add-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 20px;
    border: 1.4px solid var(--v-gray-lighten1);
    border-radius: 50px !important;
    i {
      font-size: 14px !important;
    }
  }
}
</style>
