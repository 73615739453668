<template>
  <div class="trip-item">
    <div class="font-weight-bold">
      <router-link class="disabled--text" to="/trips">Trips</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">{{ data.title_en }}</span>
    </div>
    <v-card class="mt-4" width="100%" outlined>
      <div class="d-flex wrap">
        <v-card color="item-menu py-2" flat>
          <div
            v-for="item in menuItems"
            :key="item.title"
            :class="[
              link == item.link ? 'gray lighten-4 pl-3 pr-5' : 'px-5',
              (item.link == 'filters' || item.link == 'rewiews') && role == 'trip_organizer' ? 'd-none' : '',
            ]"
            @click="clickMenu(item.link)"
            class="item-menu_item link"
          >
            <div>{{ item.title }}</div>
          </div>
          <div
            :class="link == 'saudi-surprise' ? 'gray lighten-4 pl-3 pr-5' : 'px-5'"
            @click="clickMenu('saudi-surprise')"
            class="item-menu_item link"
            v-if="data.subtype == 'surprise'"
          >
            <div class="d-flex align-center">
              <img src="@/assets/icon/surprise.svg" alt="icon" />
              <div class="ms-2">Saudi surprise</div>
            </div>
          </div>
        </v-card>
        <v-divider style="z-index: 90" vertical></v-divider>
        <div class="width100" v-if="link == 'general'">
          <Info />
        </div>
        <div class="width100" v-if="link == 'description'">
          <Description />
        </div>
        <div class="width100" v-if="link == 'activities'">
          <Activities />
        </div>
        <div class="width100" v-if="link == 'additional-info'">
          <Additional />
        </div>
        <div class="width100" v-if="link == 'schedule'">
          <Schedule />
        </div>
        <div class="width100" v-if="link == 'options'">
          <Options />
        </div>
        <div class="width100" v-if="link == 'plans'">
          <Booking />
        </div>
        <div class="width100" v-if="link == 'vat'">
          <Vat />
        </div>
        <div class="width100" v-if="link == 'filters' && role != 'trip_organizer'">
          <Filters />
        </div>
        <div class="width100" v-if="link == 'rewiews' && role != 'trip_organizer'">
          <Reviews />
        </div>
        <div class="width100" v-if="link == 'saudi-surprise' && data.subtype == 'surprise'">
          <Surprise />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import Info from '@/components/trip/Info';
import Description from '@/components/trip/Description';
import Activities from '@/components/trip/Activities';
import Additional from '@/components/trip/Additional';
import Schedule from '@/components/trip/Schedule';
import Options from '@/components/trip/Options';
import Booking from '@/components/trip/Booking';
import Surprise from '@/components/trip/Surprise';
import Reviews from '@/components/trip/Reviews';
import Filters from '@/components/trip/Filters';
import Vat from '@/components/trip/Vat';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    Info,
    Description,
    Activities,
    Additional,
    Schedule,
    Options,
    Booking,
    Surprise,
    Reviews,
    Filters,
    Vat,
  },
  data() {
    return {
      link: this.$route.params.tab || 'general',
      menuItems: [
        { link: 'general', title: 'General info' },
        { link: 'description', title: 'Description' },
        { link: 'activities', title: 'Activities and components' },
        { link: 'additional-info', title: 'Additional info' },
        { link: 'schedule', title: 'Schedule' },
        { link: 'options', title: 'Options' },
        { link: 'plans', title: 'Booking plans' },
        { link: 'vat', title: 'VAT/Service fee' },
        { link: 'filters', title: 'Filters' },
        { link: 'rewiews', title: 'Trip reviews' },
      ],
    };
  },
  mounted() {
    this.$store.dispatch('getTripsItem');
    this.$store.dispatch('getCountryList');
    this.$store.dispatch('getTripTypes');
    this.$store.dispatch('getTripCategory');
    this.$store.dispatch('getTripOrganizers');
    this.$store.dispatch('getTripTravelActiv');
    this.$store.dispatch('getTripTravelTypes');
    this.$store.dispatch('getTripTravelConponents');
  },
  methods: {
    clickMenu(l) {
      this.link = l;
      this.$router.push(`/trips/${this.$route.params.tripId}/${l}`);
    },
    async getCity() {
      await this.$store.dispatch('getCityList', this.data.departure.country.id);
    },
  },
  watch: {
    data(val) {
      if (val) {
        this.$store.dispatch('setCityList');
        val.departure ? this.getCity() : '';
        if (val.destinations.length) {
          for (let i = 0; i < val.destinations.length; i++) {
            this.$nextTick(() => {
              this.$store.dispatch('getCityListTwo', val.destinations[i].destination.country.id);
            });
          }
        }
        this.data.destinations.push({ destination: { city: {}, country: {} } });
      }
    },
    cityListTwo(val) {
      val ? this.citysList.push(val) : '';
    },
  },
  computed: {
    data() {
      return this.$store.getters.trips;
    },
    citysList() {
      return this.$store.getters.citysList;
    },
    cityList() {
      return this.$store.getters.cityList;
    },
    cityListTwo() {
      return this.$store.getters.cityListTwo;
    },
    role() {
      return this.$store.getters.profile.role;
    },
  },
  destroyed() {
    this.$store.dispatch('setTripsItem');
    this.$store.dispatch('setAccommodationShort');
    this.$store.dispatch('setCountryList');
    this.$store.dispatch('setCityList');
    this.$store.dispatch('setReviewList');
  },
};
</script>

<style lang="scss">
.trip-item {
  table thead tr th {
    background: initial !important;
  }
  table tbody tr:hover {
    background: initial !important;
  }
}
</style>
