<template>
  <div class="trip-booking" :key="key">
    <v-card tile flat min-height="52" class="d-flex flex-wrap align-center" :class="!data.length ? 'ps-3' : ''">
      <v-btn
        depressed
        :style="`box-shadow: ${day == i ? '4px -2px 4px rgba(0, 0, 0, 0.08)' : '1px -1px 4px rgba(0, 0, 0, 0.04)'}; z-index: ${
          data.length - i
        }`"
        :color="day == i ? 'white' : 'gray lighten-3 text--text'"
        class="rounded-b-0 rounded-tl-0 px-7 me-n2"
        :height="52"
        @click="day = i"
        v-for="(j, i) in data"
        :key="i"
      >
        Plan {{ i + 1 }}
      </v-btn>
      <v-btn
        v-if="!data.find((i) => i.id == undefined) && role != 'trip_organizer'"
        @click="addDay"
        icon
        class="me-4 ms-6 my-4 add-btn link"
      >
        <v-icon color="gray lighten-1">mdi-plus</v-icon>
      </v-btn>
    </v-card>
    <v-divider></v-divider>
    <v-card tile flat height="62vh" class="d-flex justify-center text-center align-center" v-if="!data.length">
      <div>
        <v-img contain height="150" src="@/assets/img/wait.svg"></v-img>
        <div class="text--text my-4">There is no booking plan added yet</div>
        <v-btn
          v-if="role != 'trip_organizer'"
          @click="addDay"
          depressed
          width="106"
          height="30"
          color="primary"
          class="text-body-2 rounded"
        >
          Add plan
        </v-btn>
      </div>
    </v-card>
    <div v-if="data.length" class="d-flex flex-column justify-space-between" style="min-height: 64vh">
      <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
        <div class="text-h6 font-weight-bold">Description</div>
        <div class="text--text mb-3">Provide the information in English and Arabic</div>
        <div class="d-flex flex-md-row flex-column multi-direct">
          <v-text-field
            class="field46 width100 me-md-3"
            v-model="data[day].title_en"
            placeholder="Title"
            :error-messages="titleEnErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
            :readonly="role == 'trip_organizer'"
          ></v-text-field>
          <v-text-field
            class="field46 width100 mt-md-0 mt-n2 ms-md-3"
            v-model="data[day].title_ar"
            placeholder="عنوان"
            :error-messages="titleArErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
            :readonly="role == 'trip_organizer'"
          ></v-text-field>
        </div>
        <div class="d-flex flex-md-row flex-column mt-n2 multi-direct">
          <v-text-field
            class="field46 width100 me-md-3"
            v-model="data[day].short_description_en"
            placeholder="Short description"
            :error-messages="desShortEnErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
            :readonly="role == 'trip_organizer'"
          ></v-text-field>
          <v-text-field
            class="field46 width100 mt-md-0 mt-n2 ms-md-3"
            v-model="data[day].short_description_ar"
            placeholder="عنوان"
            :error-messages="desShortArErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
            :readonly="role == 'trip_organizer'"
          ></v-text-field>
        </div>
        <div class="d-flex justify-space-between flex-md-row flex-column align-md-end">
          <div>
            <div class="font-weight-bold">Seats</div>
            <div class="text--text">Minimal number of seats</div>
            <v-text-field
              class="field46"
              style="max-width: 156px"
              v-model="data[day].min_seats"
              placeholder="0"
              type="number"
              :error-messages="minErrors"
              outlined
              dense
              required
              color="primary"
              height="46"
              :readonly="role == 'trip_organizer'"
            ></v-text-field>
          </div>
          <div class="mx-md-2 mt-md-0 mt-n2">
            <div class="font-weight-bold">Nights</div>
            <div class="text--text">Number of nights</div>
            <v-text-field
              class="field46"
              style="max-width: 156px"
              v-model="data[day].nights"
              placeholder="0"
              type="number"
              :error-messages="nightsErrors"
              outlined
              dense
              required
              color="primary"
              height="46"
              :readonly="role == 'trip_organizer'"
            ></v-text-field>
          </div>
          <div class="mt-md-0 mt-n2">
            <div class="font-weight-bold">Base prise</div>
            <div class="text--text">Provide the price of the Plan</div>
            <v-text-field
              class="field46"
              style="max-width: 156px"
              v-model="data[day].base_priceR"
              placeholder="0"
              suffix="SAR"
              :error-messages="basePriceErrors"
              outlined
              dense
              required
              color="primary"
              height="46"
              :readonly="role == 'trip_organizer'"
            ></v-text-field>
          </div>
        </div>
        <div class="check">
          <v-checkbox
            class="py-2 mt-0 mb-2"
            color="success"
            @change="saveDay"
            v-model="data[day].passenger_info_required"
            hide-details
            :disabled="role == 'trip_organizer'"
          >
            <template v-slot:label>
              <div class="ms-2">
                <div class="black--text font-weight-bold">Passenger info required</div>
                <div class="text-body-2 text--text">
                  Set this flag in case customer should provide Passenger information for each person that takes part in Trip. This
                  information is required in case Flight booking included in current Trip Plan
                </div>
              </div>
            </template>
          </v-checkbox>
        </div>
      </div>
      <div v-if="data[day].id">
        <v-divider></v-divider>
        <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
          <div class="font-weight-bold text-h5 mb-5">Accomodations</div>
          <v-card outlined class="mt-2 rounded-lg" v-for="(item, i) in data[day].accommodations" :key="i + 't'">
            <v-card outlined class="accommo-card rounded-lg">
              <div class="px-5 py-4">
                <div>
                  {{ item.destination.city.name }}, {{ item.destination.country.name }}:
                  {{ item.accommodation ? item.accommodation.title_en : 'Not selected' }}
                </div>
                <div class="accommo-card_arrow">
                  <v-btn color="text" height="28" width="28" rounded icon @click="openAccommo(i)">
                    <v-icon>{{ window.some((j) => j == i) ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
                  </v-btn>
                </div>
              </div>
              <div v-if="window.some((j) => j == i)">
                <table v-if="item.accommodation">
                  <tr>
                    <td>Hotel</td>
                    <td class="d-flex justify-space-between">
                      <div>{{ item.accommodation.title_en }}</div>
                      <div>{{ item.accommodation.title_ar }}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>Plane</td>
                    <td class="d-flex justify-space-between">
                      <div>{{ item.accommodation.plan.title_en }}</div>
                      <div>{{ item.accommodation.plan.title_ar }}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>Room Type</td>
                    <td class="text-capitalize">{{ item.accommodation.plan.type }}</td>
                  </tr>
                  <tr>
                    <td>Nights</td>
                    <td>{{ item.nights }}</td>
                  </tr>
                  <tr>
                    <td>Price/room</td>
                    <td>SAR {{ item.accommodation.plan.price / 100 }}</td>
                  </tr>
                </table>
              </div>
            </v-card>
            <div class="text-end py-3 px-4" v-if="window.some((j) => j == i) && role != 'trip_organizer'">
              <v-btn
                @click="item.accommodation ? (editModal = true) : (addModal = true), (dataModal = Object.assign({}, item))"
                class="rounded"
                outlined
                color="primary"
                width="156"
                height="30"
              >
                <span class="text-body-2">{{ item.accommodation ? 'Edit Accommodation' : 'Add Accommodation' }}</span>
              </v-btn>
              <v-btn
                v-if="item.accommodation"
                @click="removeAccommo(item, i)"
                width="196"
                height="30"
                outlined
                class="rounded ms-4"
                color="error"
              >
                <span class="text-body-2">Remove Accommodation</span>
              </v-btn>
            </div>
          </v-card>
          <!-- <v-card outlined class="px-4 py-5 mt-2" v-if="!data[day].accommodations.length">
            <div class="font-weight-bold mb-3">Accomodation</div>
            <div class="text--text mb-5">Provide the information about the hotel and room type that was included to the Plan.</div>
            <v-btn @click="editModal = true" class="rounded text-body-2 mt-10" depressed color="primary" width="106" height="30">
              Select
            </v-btn>
          </v-card> -->
        </div>
        <v-divider></v-divider>
        <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
          <div class="font-weight-bold mb-3">Components</div>
          <div v-for="i in data[day].components" :key="i.id" class="d-flex flex-md-row flex-column align-md-center my-4">
            <v-switch
              @change="updateComponent(i)"
              v-model="i.included"
              color="primary"
              class="white--text mt-0 mb-md-0 mb-2"
              hide-details
              inset
              :disabled="i.id == 1 || role == 'trip_organizer'"
            ></v-switch>
            <v-btn
              v-if="i.id != 1 && role != 'trip_organizer'"
              @click="removeCom(i)"
              icon
              class="d-md-none"
              style="position: absolute; right: 20px"
            >
              <v-icon color="gray lighten-1">mdi-close</v-icon>
            </v-btn>
            <v-select
              @change="i.id == 1 ? addComponent(i) : updateComponent(i)"
              v-model="i.component"
              :items="componentList"
              item-text="title_en"
              item-value="id"
              placeholder="Add new"
              outlined
              dense
              hide-details
              return-object
              class="field46"
              height="46"
              :readonly="role == 'trip_organizer'"
            >
              <template v-slot:selection="{ item }">
                <div class="d-flex align-center justify-space-between width100">
                  <div>{{ item.title_en }}</div>
                  <div class="gray--text text--lighten-3 mx-2">|</div>
                  <div class="d-flex align-center">
                    <div>{{ item.title_ar }}</div>
                    <div class="gray--text text--lighten-2 ps-2">|</div>
                  </div>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div class="d-flex align-center justify-space-between">
                  <div>{{ item.title_en }}</div>
                  <div class="gray--text text--lighten-3 mx-2">|</div>
                  <div>{{ item.title_ar }}</div>
                </div>
              </template>
            </v-select>
            <div class="d-md-block d-none" v-if="role != 'trip_organizer'">
              <v-btn v-if="i.id != 1" @click="removeCom(i)" icon class="ms-2">
                <v-icon color="gray lighten-1">mdi-close</v-icon>
              </v-btn>
              <v-card v-else tile flat width="36" height="36" class="ms-2"></v-card>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
          <div class="font-weight-bold">Plan Schedule</div>
          <div class="text--text mb-3">Select the available day when customer can booking this plan</div>
          <v-data-table
            hide-default-footer
            fixed-header
            :headers="headers"
            :items="data[day].windows"
            item-key="id"
            :items-per-page="-1"
            class="d-md-block d-none"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="(item, i) in items" :key="item.id" style="height: 70px">
                  <td>
                    <v-select
                      style="max-width: 156px"
                      @change="item.id ? updateWindow(item) : ''"
                      v-model="item.day"
                      :items="weekList"
                      item-text="title"
                      item-value="name"
                      placeholder="-"
                      outlined
                      dense
                      :color="tableErrors.length ? 'error' : 'primary'"
                      hide-details
                      class="field46 width100"
                      height="46"
                      :readonly="role == 'trip_organizer'"
                    ></v-select>
                  </td>
                  <td>
                    <v-text-field
                      style="max-width: 156px"
                      class="field46 width100"
                      :value="item.begin_time"
                      @click="role == 'trip_organizer' ? '' : openClock(item.begin_time, i)"
                      @click:append="role == 'trip_organizer' ? '' : openClock(item.begin_time, i)"
                      @change="item.id ? updateWindow(item) : ''"
                      :error-messages="timeErrors"
                      placeholder="__:__"
                      append-icon="mdi-clock-time-three"
                      hide-details
                      outlined
                      dense
                      :color="tableErrors.length ? 'error' : 'primary'"
                      height="46"
                      readonly
                      :disabled="!item.day"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      style="max-width: 156px"
                      @change="item.id ? updateWindow(item) : ''"
                      v-model="item.duration_hours"
                      placeholder="-"
                      type="number"
                      outlined
                      dense
                      color="primary"
                      hide-details
                      class="field46 width100"
                      height="46"
                      :disabled="!item.begin_time"
                      :readonly="role == 'trip_organizer'"
                    ></v-text-field>
                  </td>
                  <td class="d-flex align-center" style="height: 70px !important">
                    <v-text-field
                      style="max-width: 156px"
                      @change="item.id ? updateWindow(item) : addWindow(item)"
                      v-model="item.seats"
                      placeholder="-"
                      type="number"
                      outlined
                      dense
                      color="primary"
                      hide-details
                      class="field46 width100"
                      height="46"
                      :disabled="!item.duration_hours"
                      :readonly="role == 'trip_organizer'"
                    ></v-text-field>
                    <v-btn v-if="item.id && role != 'trip_organizer'" @click="removeWin(item, i)" icon class="ms-2">
                      <v-icon color="gray lighten-1">mdi-close</v-icon>
                    </v-btn>
                    <v-card v-else-if="role != 'trip_organizer'" tile flat min-width="36" height="36" class="ms-2"></v-card>
                  </td>
                </tr>
                <tr v-if="tableErrors.length">
                  <td colspan="4" class="text-caption error--text px-4" style="height: 25px !important">{{ tableErrors.join(' ') }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <div class="d-block d-md-none">
            <div v-for="(item, i) in data[day].windows" :key="item.id" class="px-4 pt-2">
              <div class="d-flex my-1">
                <div class="opasity--text f18 font-weight-medium label-table">Day</div>
                <v-select
                  style="max-width: 156px"
                  @change="item.id ? updateWindow(item) : ''"
                  v-model="item.day"
                  :items="weekList"
                  item-text="title"
                  item-value="name"
                  placeholder="-"
                  outlined
                  dense
                  hide-details
                  class="field46"
                  height="46"
                  :readonly="role == 'trip_organizer'"
                ></v-select>
              </div>
              <div class="d-flex my-1">
                <div class="opasity--text f18 font-weight-medium label-table">Begin time</div>
                <v-text-field
                  style="max-width: 156px"
                  class="field46"
                  :value="item.begin_time"
                  @click="openClock(item.begin_time, i)"
                  @click:append="openClock(item.begin_time, i)"
                  @change="item.id ? updateWindow(item) : ''"
                  :error-messages="timeErrors"
                  placeholder="__:__"
                  append-icon="mdi-clock-time-three"
                  hide-details
                  outlined
                  dense
                  color="primary"
                  height="46"
                  readonly
                  :disabled="!item.day"
                ></v-text-field>
              </div>
              <div class="d-flex my-1">
                <div class="opasity--text f18 font-weight-medium label-table">Duration, hours</div>
                <v-text-field
                  style="max-width: 156px"
                  @change="item.id ? updateWindow(item) : ''"
                  v-model="item.duration_hours"
                  placeholder="-"
                  type="number"
                  outlined
                  dense
                  hide-details
                  class="field46"
                  height="46"
                  :disabled="!item.begin_time"
                ></v-text-field>
              </div>
              <div class="d-flex my-1">
                <div class="opasity--text f18 font-weight-medium label-table">Number of seats</div>
                <v-text-field
                  style="max-width: 156px"
                  @change="item.id ? updateWindow(item) : addWindow(item)"
                  v-model="item.seats"
                  placeholder="-"
                  type="number"
                  outlined
                  dense
                  hide-details
                  class="field46"
                  height="46"
                  :disabled="!item.duration_hours"
                ></v-text-field>
                <v-btn v-if="item.id" @click="removeWin(item, i)" icon class="ms-2">
                  <v-icon color="gray lighten-1">mdi-close</v-icon>
                </v-btn>
              </div>
              <v-divider></v-divider>
            </div>
          </div>
        </div>
      </div>
      <div v-if="role != 'trip_organizer'">
        <v-divider></v-divider>
        <div class="pl-lg-9 px-sm-7 px-3 py-3 item-content d-flex flex-wrap">
          <v-btn @click="removeDay" width="126" height="30" outlined class="rounded" color="gray">
            <span class="error--text text-body-2">Remove plan</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="saveDay" depressed color="primary" class="text-body-2 rounded" width="106" height="30">Save</v-btn>
        </div>
      </div>
    </div>
    <v-dialog v-model="addModal" persistent max-width="460">
      <v-card tile class="pt-5 pb-7 px-7">
        <div class="font-weight-bold text-h6 mb-5">Add accommodation</div>
        <div class="mb-2">Accommodation</div>
        <v-autocomplete
          v-model="dataModal.hotel"
          placeholder="Select hotel"
          :items="accommodationList"
          item-text="title_en"
          item-value="id"
          return-object
          outlined
          dense
          class="field46"
          height="46"
        >
          <template v-slot:selection="data">
            {{ data.item.title_en }}
            <v-rating
              empty-icon="mdi-star"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              readonly
              class="ps-3"
              color="warning darken-1"
              size="16"
              :length="data.item.stars"
              :value="data.item.stars"
              v-if="data.item.stars"
            ></v-rating>
          </template>
          <template v-slot:item="data">
            {{ data.item.title_en }}
            <v-rating
              empty-icon="mdi-star"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              readonly
              class="ps-3"
              color="warning darken-1"
              size="16"
              :length="data.item.stars"
              :value="data.item.stars"
              v-if="data.item.stars"
            ></v-rating>
          </template>
        </v-autocomplete>
        <div v-if="dataModal.hotel" class="mb-2 mt-n2">Plan</div>
        <v-autocomplete
          v-if="dataModal.hotel"
          v-model="dataModal.plans"
          placeholder="Select plan"
          :items="accommodationList.find((i) => i.id === dataModal.hotel.id).plans"
          item-text="title_en"
          item-value="id"
          return-object
          outlined
          dense
          :disabled="!dataModal.hotel"
          class="field46"
          height="46"
        ></v-autocomplete>
        <div class="mb-2 mt-n2">Nights</div>
        <v-text-field
          v-model="dataModal.nights"
          placeholder="Select by title"
          outlined
          dense
          type="number"
          class="field46"
          height="46"
        ></v-text-field>
        <div class="text-end">
          <v-btn @click="addModal = false" width="106" height="30" outlined class="text-body-2 rounded me-3">Cancel</v-btn>
          <v-btn
            :disabled="!dataModal.plans || !dataModal.nights"
            @click="setAccommo"
            depressed
            width="106"
            height="30"
            color="primary"
            class="text-body-2 rounded"
          >
            Save
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editModal" persistent max-width="460">
      <v-card tile class="pt-5 pb-7 px-7" v-if="editModal">
        <div class="font-weight-bold text-h6 mb-5">Edit accommodation</div>
        <div class="mb-2">Accommodation</div>
        <v-autocomplete
          v-model="dataModal.accommodation"
          placeholder="Select hotel"
          :items="accommodationList"
          item-text="title_en"
          item-value="id"
          return-object
          outlined
          dense
          class="field46"
          height="46"
        >
          <template v-slot:selection="data">
            {{ data.item.title_en }}
            <v-rating
              empty-icon="mdi-star"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              readonly
              class="ps-3"
              color="warning darken-1"
              size="16"
              :length="data.item.stars"
              :value="data.item.stars"
              v-if="data.item.stars"
            ></v-rating>
          </template>
          <template v-slot:item="data">
            {{ data.item.title_en }}
            <v-rating
              empty-icon="mdi-star"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              readonly
              class="ps-3"
              color="warning darken-1"
              size="16"
              :length="data.item.stars"
              :value="data.item.stars"
              v-if="data.item.stars"
            ></v-rating>
          </template>
        </v-autocomplete>
        <div v-if="dataModal.accommodation.id" class="mb-2 mt-n2">Plan</div>
        <v-autocomplete
          v-if="dataModal.accommodation.id"
          v-model="dataModal.accommodation.plan"
          placeholder="Select plan"
          :items="accommodationList.find((i) => i.id === dataModal.accommodation.id).plans"
          item-text="title_en"
          item-value="id"
          return-object
          outlined
          dense
          :disabled="!dataModal.accommodation.id"
          class="field46"
          height="46"
        ></v-autocomplete>
        <div class="mb-2 mt-n2">Nights</div>
        <v-text-field
          v-model="dataModal.nights"
          placeholder="Select by title"
          outlined
          dense
          type="number"
          class="field46"
          height="46"
        ></v-text-field>
        <div class="text-end">
          <v-btn @click="editModal = false" width="106" height="30" outlined class="text-body-2 rounded me-3">Cancel</v-btn>
          <v-btn
            :disabled="!dataModal.accommodation.plan || !dataModal.nights"
            @click="setAccommo('edit')"
            depressed
            width="106"
            height="30"
            color="primary"
            class="text-body-2 rounded"
          >
            Save
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="timeModal" persistent width="290px">
      <v-time-picker v-if="timeModal" v-model="time" full-width format="24hr">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="doneClock">OK</v-btn>
      </v-time-picker>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addModal: false,
      editModal: false,
      timeModal: false,
      time: null,
      dataModal: { accommodation: {} },
      element: 0,
      idPlan: null,
      day: 0,
      key: 1,
      headers: [
        { text: 'Day', value: 'day', sortable: false },
        { text: 'Begin time', value: 'begin_time', sortable: false },
        { text: 'Duration, hours', value: 'duration_hours', sortable: false },
        { text: 'Number of seats', value: 'seats', sortable: false },
      ],
      weekList: [
        { name: 'monday', title: 'Monday' },
        { name: 'tuesday', title: 'Tuesday' },
        { name: 'wednesday', title: 'Wednesday ' },
        { name: 'thursday', title: 'Thursday ' },
        { name: 'friday', title: 'Friday ' },
        { name: 'saturday', title: 'Saturday ' },
        { name: 'sunday', title: 'Sunday ' },
      ],
      window: [],
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getBookingTripsItem');
    this.$store.dispatch('getAccommodationShort');
  },
  methods: {
    openClock(t, i) {
      this.timeModal = true;
      this.time = t;
      this.element = i;
    },
    doneClock() {
      this.timeModal = false;
      this.data[this.day].windows[this.element].begin_time = this.time;
    },
    notifi(btn) {
      if (btn == 'saved') {
        this.$notify({
          title: 'Done!',
          message: 'Booking plan added successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Booking plan updated successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'remove') {
        this.$notify({
          title: 'Done!',
          message: 'Booking plan removed',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    openAccommo(s) {
      if (this.window.some((i) => i == s)) {
        const idx = this.window.indexOf(+s);
        this.window.splice(idx, 1);
      } else {
        this.window.push(+s);
      }
    },
    addDay() {
      this.data.push({});
      this.day = this.data.length - 1;
    },
    saveDay() {
      this.error = [];
      const dataRequest = this.data[this.day];
      const data = new Object();
      data.title_en = dataRequest.title_en;
      data.title_ar = dataRequest.title_ar;
      data.short_description_en = dataRequest.short_description_en;
      data.short_description_ar = dataRequest.short_description_ar;
      // data.accommodation_plan = dataRequest.accommodation ? dataRequest.accommodation.plan.id : this.idPlan;
      data.min_seats = dataRequest.min_seats;
      data.nights = dataRequest.nights;
      data.passenger_info_required = dataRequest.passenger_info_required;
      data.base_price = dataRequest.base_priceR * 100;
      if (!dataRequest.id) {
        this.$store
          .dispatch('addBookingTripsItem', data)
          .then((res) => {
            res.data.components.push({ id: 1, included: false, component: {} });
            res.data.windows.push({ begin_time: null });
            res.data.base_priceR = res.data.base_price / 100 || '';
            this.data[this.day] = res.data;
            ++this.key;
            this.notifi('saved');
          })
          .catch((e) => {
            this.error = e.response.data.error;
          });
      } else {
        this.$store
          .dispatch('updateBookingTripsItem', { id: dataRequest.id, data: data })
          .then((res) => {
            // this.idPlan = null;
            res.data.components.push({ id: 1, included: false, component: {} });
            res.data.windows.push({ begin_time: null });
            res.data.base_priceR = res.data.base_price / 100 || '';
            this.data[this.day] = res.data;
            this.notifi('update');
          })
          .catch((e) => {
            this.error = e.response.data.error;
          });
      }
    },
    async setAccommo(t) {
      this.error = [];
      const data = new Object();
      data.destination = this.dataModal.id;
      data.nights = this.dataModal.nights;
      if (t == 'edit') {
        data.accommodation_plan = !!this.dataModal.accommodation.plans
          ? this.dataModal.accommodation.plans[0].id
          : this.dataModal.accommodation.plan.id;
      } else {
        data.accommodation_plan = this.dataModal.plans.id;
      }
      await this.$store.dispatch('setBookingTripsItemAccommo', { id: this.data[this.day].id, data: data }).then((res) => {
        res.data.components.push({ id: 1, included: false, component: {} });
        res.data.windows.push({ begin_time: null });
        res.data.base_priceR = res.data.base_price / 100 || '';
        this.data[this.day] = res.data;
        ++this.key;
        this.notifi('update');
        this.editModal = false;
        this.addModal = false;
      });
    },
    async removeAccommo(i, j) {
      this.error = [];
      await this.$store.dispatch('removeBookingTripsItemAccommo', { id: this.data[this.day].id, acc: i.id }).then(() => {
        this.notifi('update');
        this.data[this.day].accommodations[j].accommodation = null;
        ++this.key;
      });
    },
    async addComponent(i) {
      this.error = [];
      const data = new Object();
      data.travel_component = i.component.id;
      data.included = false;
      await this.$store.dispatch('addBookingTripsItemComponent', { id: this.data[this.day].id, data: data }).then((res) => {
        res.data.components.push({ id: 1, included: false, component: {} });
        res.data.windows.push({ begin_time: null });
        res.data.base_priceR = res.data.base_price / 100 || '';
        this.data[this.day] = res.data;
        ++this.key;
        this.notifi('update');
      });
    },
    async updateComponent(i) {
      this.error = [];
      const data = new Object();
      data.travel_component = i.component.id;
      data.included = i.included;
      await this.$store.dispatch('updateBookingTripsItemComponent', { id: this.data[this.day].id, data: data, com: i.id }).then(() => {
        this.notifi('update');
      });
    },
    async addWindow(i) {
      this.error = [];
      const data = new Object();
      data.day = i.day;
      data.begin_time = i.begin_time;
      data.duration_hours = +i.duration_hours;
      data.seats = +i.seats;
      await this.$store
        .dispatch('addBookingTripsItemWindow', { id: this.data[this.day].id, data: data })
        .then((res) => {
          res.data.components.push({ id: 1, included: false, component: {} });
          res.data.windows.push({ begin_time: null });
          res.data.base_priceR = res.data.base_price / 100 || '';
          this.data[this.day] = res.data;
          ++this.key;
          this.notifi('update');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async updateWindow(i) {
      this.error = [];
      const data = new Object();
      data.day = i.day;
      data.begin_time = i.begin_time;
      data.duration_hours = +i.duration_hours;
      data.seats = +i.seats;
      await this.$store
        .dispatch('updateBookingTripsItemWindow', { id: this.data[this.day].id, data: data, win: i.id })
        .then(() => {
          this.notifi('update');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async removeDay() {
      const i = this.day;
      this.day == 0 ? (this.day = 0) : this.day--;
      if (this.data[i].id) {
        await this.$store.dispatch('removeBookingTripsItem', this.data[i].id).then(() => {
          this.data.splice(i, 1);
        });
      } else {
        this.data.splice(i, 1);
      }
      ++this.key;
      this.notifi('remove');
    },
    async removeCom(i, idx) {
      await this.$store.dispatch('removeBookingTripsItemComponent', { id: this.data[this.day].id, com: i.id }).then(() => {
        this.data[this.day].components.splice(idx, 1);
        ++this.key;
        this.notifi('update');
      });
    },
    async removeWin(i, idx) {
      await this.$store.dispatch('removeBookingTripsItemWindow', { id: this.data[this.day].id, win: i.id }).then(() => {
        this.data[this.day].windows.splice(idx, 1);
        ++this.key;
        this.notifi('update');
      });
    },
  },
  watch: {
    day() {
      this.error = [];
    },
  },
  computed: {
    data() {
      return this.$store.getters.tripsBooking;
    },
    componentList() {
      return this.$store.getters.tripTvComponent;
    },
    accommodationList() {
      return this.$store.getters.accommodationContentListShort;
    },
    role() {
      return this.$store.getters.profile.role;
    },
    titleEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_en__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_en__invalid') && errors.push('Provided title is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    titleArErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_ar__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_ar__invalid') && errors.push('Provided title is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desShortEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'short_description_en__required') && errors.push('Please enter short description');
      this.error.find((item) => item == 'short_description_en__invalid') && errors.push('Provided short description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desShortArErrors() {
      const errors = [];
      this.error.find((item) => item == 'short_description_ar__invalid') && errors.push('Provided short description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    minErrors() {
      const errors = [];
      this.error.find((item) => item == 'min_seats__required') && errors.push('Min seats count is not provided');
      this.error.find((item) => item == 'min_seats__invalid') && errors.push('Provided min seats count is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    nightsErrors() {
      const errors = [];
      this.error.find((item) => item == 'nights__required') && errors.push('Nights count is not provided');
      this.error.find((item) => item == 'nights__invalid') && errors.push('Provided nights count is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    basePriceErrors() {
      const errors = [];
      this.error.find((item) => item == 'base_price__required') && errors.push('Please enter base price');
      this.error.find((item) => item == 'base_price__invalid') && errors.push('Provided base price is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    timeErrors() {
      const errors = [];
      this.error.find((item) => item == 'time__required') && errors.push('Please enter time');
      this.error.find((item) => item == 'time__invalid') && errors.push('Provided time is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    tableErrors() {
      const errors = [];
      this.error.find((item) => item == 'internal') && errors.push('Sorry, but window with the same day and/or time already exists');
      this.error.find((item) => item == 'window__exists') && errors.push('Sorry, but window with the same day and/or time already exists');
      return errors;
    },
  },
};
</script>

<style lang="scss">
.trip-booking {
  .check {
    .v-input__slot {
      align-items: initial;
    }
  }
  .add-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 20px;
    border: 1.4px solid var(--v-gray-lighten1);
    border-radius: 50px !important;
    i {
      font-size: 14px !important;
    }
  }
  .accommo-card {
    // border: 1px solid var(--v-gray-lighten2);
    background: var(--v-gray-lighten4);
    &_arrow {
      position: absolute;
      top: 16px;
      right: 20px;
      z-index: 5;

      .mdi::before {
        height: 24px !important;
      }
    }
    table {
      border-collapse: collapse;
      width: 100%;
    }
    td,
    th {
      border-top: thin solid #dddddd;
      padding: 12px 16px;
      color: black;
    }
    td:first-of-type {
      color: var(--v-text-base);
      width: 150px;
      border-right: thin solid #dddddd;
    }
  }
  .text-end {
    .v-btn.primary--text {
      background: var(--v-primary-lighten3);
    }
    .v-btn.error--text {
      background: var(--v-error-lighten5);
    }
  }
}
.v-application--is-rtl {
  .trip-booking {
    .accommo-card {
      &_arrow {
        right: inherit;
        left: 20px;
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }
}
</style>
