<template>
  <div class="trip-review">
    <div class="pa-sm-10 pt-sm-6 pa-3">
      <div class="d-flex">
        <div>
          <div class="text-h6 font-weight-bold">Reviews</div>
          <div class="text--text">{{ array.length }} items</div>
        </div>
        <v-spacer></v-spacer>
        <v-btn @click="modalNew = true" depressed color="primary" width="136" height="44">Add review</v-btn>
      </div>
      <div class="d-flex flex-wrap">
        <v-card
          v-for="(i, j) in array"
          :key="i.id"
          class="rounded py-5 px-4 me-3 mt-4 d-flex flex-column"
          outlined
          max-width="370"
          width="100%"
        >
          <div class="d-flex">
            <v-avatar color="gray lighten-2" size="40">
              <v-img v-if="i.picture != null" :src="i.picture"></v-img>
              <div v-else class="d-flex align-center">
                <v-img class="gray-filter" contain width="24" height="24" src="@/assets/icon/user.svg"></v-img>
              </div>
            </v-avatar>
            <div class="ms-3">
              <div v-if="i.customer" class="font-weight-bold">{{ i.customer.first_name }} {{ i.customer.last_name }}</div>
              <div v-else class="font-weight-bold">{{ i.first_name }} {{ i.last_name }}</div>
              <div class="disabled--text f13">
                {{ new Date(i.submitted_at).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) }}
              </div>
            </div>
            <v-spacer></v-spacer>
            <v-card outlined class="rounded d-flex align-center">
              <v-btn @click="openEdit(i, j)" width="48" height="38" icon v-if="i.type != 'customer'">
                <v-img contain width="24" height="24" src="@/assets/icon/edit.svg"></v-img>
              </v-btn>
              <v-divider vertical v-if="i.type != 'customer'"></v-divider>
              <v-btn @click="openDelete(i, j)" width="48" height="38" icon>
                <v-img contain width="24" height="24" src="@/assets/icon/delete.svg"></v-img>
              </v-btn>
            </v-card>
          </div>
          <v-rating
            empty-icon="mdi-star"
            full-icon="mdi-star"
            half-icon="mdi-star-half-full"
            size="16"
            color="warning darken-1"
            readonly
            :value="i.rating"
            :length="i.rating"
          ></v-rating>
          <div class="mb-3 mt-1">{{ i.text }}</div>
          <v-spacer></v-spacer>
          <div class="f13 text-capitalize"><span class="disabled--text">Source: </span> {{ i.type }}</div>
          <div class="d-flex mt-4">
            <v-card flat class="d-flex align-center rounded-lg text--text gray lighten-4 px-4 py-1 f13 me-4">
              <v-icon class="me-2" color="primary">{{ i.featured ? 'mdi-check' : 'mdi-close' }}</v-icon> Featured
            </v-card>
            <v-card flat class="d-flex align-center rounded-lg text--text gray lighten-4 px-4 py-1 f13">
              <v-icon class="me-2" color="primary">{{ i.home_featured ? 'mdi-check' : 'mdi-close' }}</v-icon> Show on home
            </v-card>
          </div>
        </v-card>
      </div>
      <div v-if="page != data.pages && data.pages" class="mt-2 pb-2 px-3 text-center">
        <v-btn @click="++page, getData()" text>View more</v-btn>
      </div>
    </div>
    <v-dialog v-model="modalNew" max-width="460">
      <v-card v-if="modalNew">
        <div class="text-center py-4">
          <div class="text-h6 font-weight-bold mb-3">Add Review</div>
          <v-btn-toggle class="group-btn" v-model="modalData.type">
            <v-btn class="text-body-2" value="platform" outlined height="36">Platform</v-btn>
            <v-btn class="text-body-2" value="marketing" outlined height="36">Marketing</v-btn>
          </v-btn-toggle>
        </div>
        <v-divider></v-divider>
        <v-card flat tile class="view-without-scroll" max-height="416">
          <div class="px-8 pt-4 pb-6">
            <div v-if="modalData.type == 'platform'">
              <div class="mb-1">Customer</div>
              <v-autocomplete
                class="field46"
                v-model="modalData.customer"
                placeholder="Select Customer"
                :error-messages="customerErrors"
                :items="customerList"
                :item-text="textItem"
                item-value="id"
                outlined
                dense
                required
                color="primary"
                height="46"
              ></v-autocomplete>
            </div>
            <div v-else>
              <div class="link mb-4" @click="changeAvatar">
                <v-card
                  v-if="!modalData.picture && !image"
                  class="d-flex align-center mx-auto rounded-circle foto-card"
                  flat
                  width="84"
                  height="84"
                >
                  <v-img class="gray-filter" contain height="56" src="@/assets/icon/user.svg"></v-img>
                </v-card>
                <v-img class="mx-auto rounded-circle" cover v-else height="84" max-width="84" :src="image || modalData.picture"></v-img>
              </div>
              <div class="mb-1">Person name</div>
              <div class="d-flex">
                <v-text-field
                  class="field46 me-2"
                  v-model="modalData.first_name"
                  :error-messages="firstNameErrors"
                  placeholder="First Name"
                  outlined
                  dense
                  color="primary"
                  height="46"
                ></v-text-field>
                <v-text-field
                  class="field46 ms-2"
                  v-model="modalData.last_name"
                  :error-messages="lastNameErrors"
                  placeholder="Last Name"
                  outlined
                  dense
                  color="primary"
                  height="46"
                ></v-text-field>
              </div>
            </div>
            <div class="mb-1">Review</div>
            <v-rating
              empty-icon="mdi-star"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              size="32"
              background-color="gray lighten-1"
              color="warning darken-1"
              v-model="modalData.rating"
            ></v-rating>
            <v-textarea
              class="field46 mt-2"
              v-model="modalData.text"
              placeholder="Provide review"
              :error-messages="textErrors"
              auto-grow
              rows="4"
              row-height="30"
              outlined
              dense
              required
              color="primary"
            ></v-textarea>
            <div class="mb-1">Submitting date/time</div>
            <vc-date-picker
              :popover="{ visibility: 'click' }"
              locale="en"
              :input-debounce="500"
              v-model="modalData.submitted_at"
              :masks="masks"
              :model-config="modelConfig"
              :minute-increment="5"
              mode="dateTime"
              is24hr
              :first-day-of-week="2"
              is-required
              color="blue"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <v-text-field
                  class="field46"
                  :value="inputValue"
                  v-on="inputEvents"
                  :error-messages="dateErrors"
                  placeholder="Date __:__"
                  outlined
                  dense
                  color="primary"
                  height="46"
                  readonly
                >
                  <template v-slot:append>
                    <v-img class="mt-2" src="@/assets/icon/calendar.svg"></v-img>
                  </template>
                </v-text-field>
              </template>
            </vc-date-picker>
            <v-checkbox class="py-2 mt-0 mb-2" color="success" v-model="modalData.featured" hide-details>
              <template v-slot:label>
                <div class="ms-2">
                  <div class="black--text">Featured review</div>
                </div>
              </template>
            </v-checkbox>
            <v-checkbox class="py-2 mt-0" color="success" v-model="modalData.home_featured" hide-details>
              <template v-slot:label>
                <div class="ms-2">
                  <div class="black--text">Show on Home page</div>
                </div>
              </template>
            </v-checkbox>
          </div>
        </v-card>
        <v-divider></v-divider>
        <div class="text-end px-4 py-5">
          <v-btn @click="modalNew = false" class="rounded" outlined width="106" height="30">Cancel</v-btn>
          <v-btn @click="addReview" depressed class="primary ms-3 rounded" width="106" height="30">Save</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalEdit" max-width="460">
      <v-card v-if="modalEdit">
        <div class="text-center py-4">
          <div class="text-h6 font-weight-bold mb-3">Edit Review</div>
        </div>
        <v-divider></v-divider>
        <v-card flat tile class="view-without-scroll" max-height="464">
          <div class="px-8 pt-4 pb-6">
            <div v-if="modalData.type == 'marketing'">
              <div class="link mb-4" @click="changeAvatar">
                <v-card
                  v-if="!modalData.picture && !image"
                  class="d-flex align-center mx-auto rounded-circle foto-card"
                  flat
                  width="84"
                  height="84"
                >
                  <v-img class="gray-filter" contain height="56" src="@/assets/icon/user.svg"></v-img>
                </v-card>
                <v-img class="mx-auto rounded-circle" cover v-else height="84" max-width="84" :src="image || modalData.picture"></v-img>
              </div>
              <div class="mb-1">Person name</div>
              <div class="d-flex">
                <v-text-field
                  class="field46 me-2"
                  v-model="modalData.first_name"
                  :error-messages="firstNameErrors"
                  placeholder="First Name"
                  outlined
                  dense
                  color="primary"
                  height="46"
                ></v-text-field>
                <v-text-field
                  class="field46 ms-2"
                  v-model="modalData.last_name"
                  :error-messages="lastNameErrors"
                  placeholder="Last Name"
                  outlined
                  dense
                  color="primary"
                  height="46"
                ></v-text-field>
              </div>
            </div>
            <div v-else class="d-flex mb-5">
              <v-avatar color="gray lighten-2" size="40">
                <v-img v-if="modalData.picture != null" :src="modalData.picture"></v-img>
                <div v-else class="d-flex align-center">
                  <v-img class="gray-filter" contain width="24" height="24" src="@/assets/icon/user.svg"></v-img>
                </div>
              </v-avatar>
              <div class="ms-3">
                <div v-if="modalData.customer" class="font-weight-bold">
                  {{ modalData.customer.first_name }} {{ modalData.customer.last_name }}
                </div>
                <div class="disabled--text f13">
                  {{ new Date(modalData.submitted_at).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) }}
                </div>
              </div>
            </div>
            <div v-if="modalData.type != 'customer'">
              <div class="mb-1">Review</div>
              <v-rating
                empty-icon="mdi-star"
                full-icon="mdi-star"
                half-icon="mdi-star-half-full"
                size="32"
                background-color="gray lighten-1"
                color="warning darken-1"
                v-model="modalData.rating"
              ></v-rating>
              <v-textarea
                class="field46 mt-2"
                v-model="modalData.text"
                placeholder="Provide review"
                :error-messages="textErrors"
                auto-grow
                rows="4"
                row-height="30"
                outlined
                dense
                required
                color="primary"
              ></v-textarea>
              <div v-if="modalData.type == 'marketing'">
                <div class="mb-1">Submitting date/time</div>
                <vc-date-picker
                  :popover="{ visibility: 'click' }"
                  locale="en"
                  :input-debounce="500"
                  v-model="modalData.submitted_at"
                  :masks="masks"
                  :model-config="modelConfig"
                  :minute-increment="5"
                  mode="dateTime"
                  is24hr
                  :first-day-of-week="2"
                  is-required
                  color="blue"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <v-text-field
                      class="field46"
                      :value="inputValue"
                      v-on="inputEvents"
                      :error-messages="dateErrors"
                      placeholder="Date __:__"
                      outlined
                      dense
                      color="primary"
                      height="46"
                      readonly
                    >
                      <template v-slot:append>
                        <v-img class="mt-2" src="@/assets/icon/calendar.svg"></v-img>
                      </template>
                    </v-text-field>
                  </template>
                </vc-date-picker>
              </div>
            </div>
            <v-checkbox class="py-2 mt-0 mb-2" color="success" v-model="modalData.featured" hide-details>
              <template v-slot:label>
                <div class="ms-2">
                  <div class="black--text">Featured review</div>
                </div>
              </template>
            </v-checkbox>
            <v-checkbox class="py-2 mt-0" color="success" v-model="modalData.home_featured" hide-details>
              <template v-slot:label>
                <div class="ms-2">
                  <div class="black--text">Show on Home page</div>
                </div>
              </template>
            </v-checkbox>
          </div>
        </v-card>
        <v-divider></v-divider>
        <div class="text-end px-4 py-5">
          <v-btn @click="modalEdit = false" class="rounded" outlined width="106" height="30">Cancel</v-btn>
          <v-btn @click="updateReview" depressed class="primary ms-3 rounded" width="106" height="30">Save</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalDelete" max-width="480">
      <v-card class="px-4 pt-4 pb-5">
        <div class="text-h6 font-weight-bold mb-3">Do you really want to delete this review?</div>
        <div class="text--text">The information cannot be restored!</div>
        <div class="text-end mt-5">
          <v-btn @click="modalDelete = false" class="rounded" outlined width="106" height="30">Cancel</v-btn>
          <v-btn @click="removeReview" depressed class="error ms-3 rounded" width="106" height="30">Remove</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalNew: false,
      modalEdit: false,
      modalDelete: false,
      page: 1,
      modalData: {
        type: 'platform',
        submitted_at: null,
        featured: false,
        home_featured: false,
      },
      image: '',
      masks: {
        inputDateTime24hr: 'MMM D, YYYY HH:mm',
      },
      modelConfig: {
        type: 'number',
        mask: 'ISO',
      },
      array: [],
      error: [],
    };
  },
  mounted() {
    this.getData();
    this.$store.dispatch('getCustomerShort');
  },
  methods: {
    textItem: (item) => `${item.first_name} ${item.last_name}`,
    notifi(btn) {
      if (btn == 'create') {
        this.$notify({
          title: 'Done!',
          message: 'Review add successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Review updated successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'remove') {
        this.$notify({
          title: 'Done!',
          message: 'Review item remove',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async getData() {
      const data = new Object();
      data.page = this.page;
      data.type = `?trip=${this.$route.params.tripId}&`;
      await this.$store.dispatch('getReviewList', data);
    },
    changeAvatar() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.modalData.picture = this.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            self.image = e.target.result;
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    openEdit(i, j) {
      this.modalData = Object.assign({}, i);
      this.modalData.pos = j;
      this.modalEdit = true;
    },
    openDelete(i, j) {
      this.modalData = Object.assign({}, i);
      this.modalData.pos = j;
      this.modalDelete = true;
    },
    async updateReview() {
      this.error = [];
      const formData = new FormData();
      for (var key in this.modalData) {
        formData.append(key, this.modalData[key]);
      }
      formData.append('trip', this.$route.params.tripId);
      await this.$store
        .dispatch('updateReviewItem', { data: formData, id: this.modalData.id })
        .then((res) => {
          this.data.result[this.modalData.pos] = res.data;
          this.notifi('update');
          this.modalEdit = false;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async addReview() {
      this.error = [];
      const formData = new FormData();
      for (var key in this.modalData) {
        formData.append(key, this.modalData[key]);
      }
      formData.append('trip', this.$route.params.tripId);
      await this.$store
        .dispatch('createReview', formData)
        .then((res) => {
          this.notifi('create');
          this.modalNew = false;
          this.array.unshift(res.data);
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async removeReview() {
      this.error = [];
      await this.$store
        .dispatch('deleteReview', this.modalData.id)
        .then(() => {
          this.array.splice(this.modalData.pos, 1);
          this.modalDelete = false;
          this.notifi('remove');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    cleanModal() {
      this.error = [];
      this.modalData = Object.assign({}, {});
      this.modalData.type = 'platform';
      this.modalData.submitted_at = null;
      this.modalData.featured = false;
      this.modalData.home_featured = false;
    },
  },
  watch: {
    modalNew(val) {
      val ? '' : this.cleanModal();
    },
    modalEdit(val) {
      val ? '' : this.cleanModal();
    },
    modalDelete(val) {
      val ? '' : this.cleanModal();
    },
    data(val) {
      if (val) {
        for (let i = 0; i < this.data.result.length; i++) {
          this.array.push(this.data.result[i]);
        }
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.reviewList;
    },
    customerList() {
      return this.$store.getters.customerShort;
    },
    customerErrors() {
      const errors = [];
      this.error.find((item) => item == 'customer__required') && errors.push('Please select customer');
      this.error.find((item) => item == 'customer__invalid') && errors.push('Selected customer is not valid');
      return errors;
    },
    firstNameErrors() {
      const errors = [];
      this.error.find((item) => item == 'first_name__required') && errors.push('Please enter first name');
      this.error.find((item) => item == 'first_name__invalid') && errors.push('Provided first name is not valid');
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      this.error.find((item) => item == 'last_name__required') && errors.push('Please enter last name');
      this.error.find((item) => item == 'last_name__invalid') && errors.push('Provided last name is not valid');
      return errors;
    },
    dateErrors() {
      const errors = [];
      this.error.find((item) => item == 'submitted_at__required') && errors.push('Please enter date');
      this.error.find((item) => item == 'submitted_at__invalid') && errors.push('Provided date is not valid');
      return errors;
    },
    textErrors() {
      const errors = [];
      this.error.find((item) => item == 'text__required') && errors.push('Please enter text');
      this.error.find((item) => item == 'text__invalid') && errors.push('Provided text is not valid');
      this.error.find((item) => item == 'rating__required') && errors.push('Please enter rating');
      this.error.find((item) => item == 'rating__invalid') && errors.push('Provided rating is not valid');
      return errors;
    },
  },
};
</script>
