<template>
  <div class="trip-vat d-flex flex-column" style="min-height: 100%">
    <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
      <div class="text-h6 font-weight-bold">VAT/Service fee</div>
      <div class="text--text mb-3">Here you can manage VAT and Service fee options for the trip</div>
      <div class="font-weight-bold" :class="{ 'mb-3': data.locality != 'international' }">VAT type</div>
      <div class="disabled--text mb-3" v-if="data.locality == 'international'">
        Selected VAT will be applied to the Service fee amount for international trip locality
      </div>
      <div class="d-flex align-md-center flex-md-row flex-column">
        <div class="v-input d-block width100 me-md-3">
          <v-select
            class="field46"
            v-model="data.vat_option"
            placeholder="Select type"
            :error-messages="vatOptionEnErrors"
            :items="itemsVat"
            item-text="title"
            item-value="id"
            return-object
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-select>
        </div>
        <div class="v-input width100 mt-md-0 mt-n2 ms-md-3">
          <v-checkbox class="py-2 mt-3" color="success" v-model="data.vat_included" hide-details>
            <template v-slot:label>
              <div class="ms-2">
                <div class="black--text">VAT included in price</div>
              </div>
            </template>
          </v-checkbox>
        </div>
      </div>
      <div class="d-flex align-md-center flex-md-row flex-column">
        <div class="v-input d-block width100 me-md-3">
          <div class="font-weight-bold mb-3">Service fee type <span class="disabled--text font-weight-regular">(optional)</span></div>
          <v-select
            class="field46"
            v-model="data.service_fee_option"
            placeholder="Select type"
            :error-messages="serviceFeeOptionEnErrors"
            :items="itemsServiceFee"
            item-text="title"
            item-value="id"
            return-object
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-select>
        </div>
        <div class="v-input width100 mt-md-0 mt-n2 ms-md-3">
          <v-checkbox class="py-2 mt-3" color="success" v-model="data.service_fee_included" hide-details>
            <template v-slot:label>
              <div class="ms-2">
                <div class="black--text">Service fee included in price</div>
              </div>
            </template>
          </v-checkbox>
        </div>
      </div>
    </div>
    <v-spacer></v-spacer>
    <v-divider class="mt-2" v-if="role != 'trip_organizer'"></v-divider>
    <div class="text-end py-3 px-sm-10 px-3" v-if="role != 'trip_organizer'">
      <v-btn @click="updateItem" depressed color="primary" large width="136">Save</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getSettingsVat', '?all=true');
    this.$store.dispatch('getSettingsServiceFee', '?all=true');
  },
  methods: {
    notifi(btn) {
      if (btn == 'saved') {
        this.$notify({
          title: 'Done!',
          message: 'Trip saved successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Trip updated successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'remove') {
        this.$notify({
          title: 'Done!',
          message: 'Trip media remove',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async updateItem() {
      this.error = [];
      const data = new Object();
      this.data.organizer ? (data.organizer = this.data.organizer.id) : '';
      this.data.type ? (data.type = this.data.type.id) : '';
      data.subtype = this.data.subtype;
      data.locality = this.data.locality;
      if (this.data.departure) {
        this.data.departure.city ? (data.departure_city = this.data.departure.city.id) : '';
      }
      data.departure_address = this.data.departure_address;
      this.data.category ? (data.category = this.data.category.id) : '';
      data.available_since = this.data.available_since;
      data.available_till = this.data.available_till;
      data.title_en = this.data.title_en;
      data.title_ar = this.data.title_ar;
      data.short_description_en = this.data.short_description_en;
      data.short_description_ar = this.data.short_description_ar;
      data.description_en = this.data.description_en;
      data.description_ar = this.data.description_ar;
      this.data.vat_option ? (data.vat_option = this.data.vat_option.id) : '';
      data.vat_included = this.data.vat_included;
      this.data.service_fee_option ? (data.service_fee_option = this.data.service_fee_option.id) : '';
      data.service_fee_included = this.data.service_fee_included;
      await this.$store
        .dispatch('updateTripsItem', data)
        .then(() => {
          this.notifi('saved');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  computed: {
    data() {
      return this.$store.getters.trips;
    },
    role() {
      return this.$store.getters.profile.role;
    },
    itemsVat() {
      return this.$store.getters.settingsVat;
    },
    itemsServiceFee() {
      return this.$store.getters.settingsServiceFee;
    },
    vatOptionEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'vat_option__required') && errors.push('Please select VAT type');
      this.error.find((item) => item == 'vat_option__invalid') && errors.push('Selected VAT type is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    serviceFeeOptionEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'service_fee_option__required') && errors.push('Please select service fee type');
      this.error.find((item) => item == 'service_fee_option__invalid') && errors.push('Selected service fee type is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
  },
};
</script>

<style lang="scss">
#app .trip-vat {
  .v-input {
    max-width: 371px;
  }
  button {
    border-radius: 4px !important;
  }
  @media screen and (max-width: 700px) {
    .v-input {
      max-width: initial !important;
    }
  }
}
</style>
