<template>
  <div class="trip-desc">
    <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
      <div class="text-h6 font-weight-bold">Description</div>
      <div class="text--text mb-3">Provide the information in English and Arabic</div>
      <div class="d-flex flex-md-row flex-column multi-direct">
        <v-text-field
          class="field46 width100 me-md-3"
          v-model="data.title_en"
          placeholder="Title"
          :error-messages="desTitleEnErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
          :readonly="role == 'trip_organizer'"
        ></v-text-field>
        <v-text-field
          class="field46 width100 mt-md-0 mt-n2 ms-md-3"
          v-model="data.title_ar"
          placeholder="عنوان"
          :error-messages="desTitleArErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
          :readonly="role == 'trip_organizer'"
        ></v-text-field>
      </div>
      <div class="d-flex flex-md-row flex-column mt-n2 multi-direct">
        <v-text-field
          class="field46 width100 me-md-3"
          v-model="data.short_description_en"
          placeholder="Short description"
          :error-messages="desShortEnErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
          :readonly="role == 'trip_organizer'"
        ></v-text-field>
        <v-text-field
          class="field46 width100 mt-md-0 mt-n2 ms-md-3"
          v-model="data.short_description_ar"
          placeholder="وصف قصير"
          :error-messages="desShortArErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
          :readonly="role == 'trip_organizer'"
        ></v-text-field>
      </div>
      <div class="d-flex flex-md-row flex-column mt-n2 multi-direct">
        <v-textarea
          class="width100 me-md-3"
          v-model="data.description_en"
          placeholder="Description"
          :error-messages="desDesEnErrors"
          auto-grow
          rows="4"
          row-height="30"
          outlined
          dense
          required
          color="primary"
          :readonly="role == 'trip_organizer'"
        ></v-textarea>
        <v-textarea
          class="width100 mt-md-0 mt-n2 ms-md-3"
          v-model="data.description_ar"
          placeholder="وصف كامل"
          :error-messages="desDesArErrors"
          auto-grow
          rows="4"
          row-height="30"
          outlined
          dense
          required
          color="primary"
          :readonly="role == 'trip_organizer'"
        ></v-textarea>
      </div>
      <div class="text-h6 font-weight-bold">Photos</div>
      <div class="text--text mb-4">Please, drag the main photo to the first place.</div>
      <v-card flat class="primary lighten-3 d-flex align-center justify-center" height="440" rounded="lg" style="position: relative">
        <div v-if="!data.media.length">
          <v-img class="mb-3" contain height="48" src="@/assets/icon/img.svg"></v-img>
          <v-btn @click="addPhoto" depressed width="116" height="30" class="primary text-body-2">Choose media</v-btn>
        </div>
        <div v-else class="d-flex align-center justify-center" :key="key">
          <v-img v-if="data.media[pos].type == 'picture'" class="rounded-lg" contain :src="data.media[pos].url" height="440"></v-img>
          <video v-if="data.media[pos].type == 'video'" class="rounded-lg" @click="playVideo" @ended="playVideo" id="video" height="440">
            <source :src="data.media[pos].url" />
          </video>
          <v-img
            width="100%"
            id="btnVideo"
            cover
            @click="playVideo"
            v-if="data.media[pos].type == 'video'"
            class="myvideo link rounded-lg"
            :src="data.media[pos].preview_url"
          >
            <v-icon style="font-size: 100px">mdi-play-circle</v-icon>
          </v-img>
        </div>
        <div class="galery d-flex align-center pa-3" v-if="data.media.length">
          <div class="d-flex align-center width100" style="max-width: 610px">
            <v-slide-group next-icon="mdi-chevron-right-circle" prev-icon="mdi-chevron-left-circle">
              <v-slide-item v-for="(j, i) in data.media" :key="j.id">
                <v-img
                  @click="pickImg(i)"
                  class="rounded-lg link d-flex align-center text-center"
                  cover
                  :src="j.type == 'picture' ? j.url : j.preview_url"
                  height="92"
                  max-width="92"
                >
                  <div @click.stop="removeImg(j.id)" v-if="pos == i && role != 'trip_organizer'" class="close-btn">
                    <v-icon color="white">mdi-close</v-icon>
                  </div>
                  <v-icon v-if="j.type == 'video'">mdi-play-circle mdi-48px</v-icon>
                </v-img>
              </v-slide-item>
            </v-slide-group>
          </div>
          <v-spacer></v-spacer>
          <v-btn v-if="role != 'trip_organizer'" @click="addPhoto" class="add-btn ms-3" icon large>
            <img src="@/assets/icon/btn.svg" alt="plus" />
          </v-btn>
        </div>
      </v-card>
    </div>
    <v-divider class="mt-2" v-if="role != 'trip_organizer'"></v-divider>
    <div class="text-end py-3 px-sm-10 px-3" v-if="role != 'trip_organizer'">
      <v-btn @click="updateItem" depressed color="primary" large width="136">Save</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      key: 1,
      pos: 0,
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'saved') {
        this.$notify({
          title: 'Done!',
          message: 'Trip saved successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Trip updated successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'remove') {
        this.$notify({
          title: 'Done!',
          message: 'Trip media remove',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'mediaInvalid') {
        this.$notify({
          title: 'Error',
          message: 'Sorry, but provided media can not be used',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    playVideo() {
      const video = document.querySelector('#video');
      const btn = document.querySelector('#btnVideo');
      if (video.paused) {
        video.play();
        btn.style.display = 'none';
      } else {
        video.pause();
        btn.style.display = 'flex';
      }
    },
    endVideo() {
      const btn = document.querySelector('#btnVideo');
      btn.style.display = 'flex';
    },
    addPhoto() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', '.mp4,.mov,image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.addMediaItem(this.files[0]);
          var reader = new FileReader();
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    pickImg(i) {
      const video = document.querySelector('#video');
      if (video) {
        video.pause();
        this.endVideo();
      }
      this.$nextTick(() => {
        ++this.key;
        this.pos = i;
      });
    },
    async updateItem() {
      this.error = [];
      const data = new Object();
      this.data.organizer ? (data.organizer = this.data.organizer.id) : '';
      this.data.type ? (data.type = this.data.type.id) : '';
      data.subtype = this.data.subtype;
      data.locality = this.data.locality;
      if (this.data.departure) {
        this.data.departure.city ? (data.departure_city = this.data.departure.city.id) : '';
      }
      data.departure_address = this.data.departure_address;
      this.data.category ? (data.category = this.data.category.id) : '';
      data.available_since = this.data.available_since;
      data.available_till = this.data.available_till;
      data.title_en = this.data.title_en;
      data.title_ar = this.data.title_ar;
      data.short_description_en = this.data.short_description_en;
      data.short_description_ar = this.data.short_description_ar;
      data.description_en = this.data.description_en;
      data.description_ar = this.data.description_ar;
      this.data.vat_option ? (data.vat_option = this.data.vat_option.id) : '';
      data.vat_included = this.data.vat_included;
      this.data.service_fee_option ? (data.service_fee_option = this.data.service_fee_option.id) : '';
      data.service_fee_included = this.data.service_fee_included;
      await this.$store
        .dispatch('updateTripsItem', data)
        .then(() => {
          this.notifi('saved');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async addMediaItem(f) {
      this.error = [];
      const formData = new FormData();
      if (f.type.substr(0, 5) != 'video') {
        formData.append('type', 'picture');
      } else {
        formData.append('type', 'video');
      }
      formData.append('media', f);
      await this.$store
        .dispatch('mediaTripsItem', formData)
        .then(() => {
          this.notifi('update');
        })
        .catch((e) => {
          this.error = e.response.data.error;
          if (this.error.find((item) => item == 'media__invalid')) {
            this.notifi('mediaInvalid');
          }
        });
    },
    async removeImg(id) {
      this.error = [];
      await this.$store
        .dispatch('mediaDelTripsItem', id)
        .then(() => {
          this.pos ? this.pos-- : '';
          this.notifi('remove');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  computed: {
    data() {
      return this.$store.getters.trips;
    },
    role() {
      return this.$store.getters.profile.role;
    },
    desTitleEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_en__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_en__invalid') && errors.push('Provided title is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desTitleArErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_ar__invalid') && errors.push('Provided title is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desShortEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'short_description_en__required') && errors.push('Please enter short description');
      this.error.find((item) => item == 'short_description_en__invalid') && errors.push('Provided short description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desShortArErrors() {
      const errors = [];
      this.error.find((item) => item == 'short_description_ar__invalid') && errors.push('Provided short description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desDesEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_en__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_en__invalid') && errors.push('Provided description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desDesArErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_ar__invalid') && errors.push('Provided description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
  },
};
</script>

<style lang="scss">
#app .trip-desc {
  .v-input {
    max-width: 371px;
  }
  button {
    border-radius: 4px !important;
  }
  .myvideo {
    position: absolute;
    align-items: center;
    text-align: center;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .myvideo:after {
    display: none !important;
  }
  .galery {
    position: absolute;
    width: 100%;
    max-width: 706px;
    top: 70%;
    margin: 0 30px;
    background: rgba(15, 15, 15, 0.58);
    border-radius: 8px;
    backdrop-filter: blur(9px);
    .v-image:not(:last-of-type) {
      margin-right: 10px;
    }
    .add-btn {
      // position: absolute;
      border-radius: 50% !important;
      // right: 12px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .close-btn {
      cursor: pointer;
      position: absolute;
      left: 75%;
      top: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.6);
      i {
        font-size: 12px !important;
      }
    }
    .v-slide-group {
      .v-slide-group__prev,
      .v-slide-group__next {
        min-width: 0;
        max-width: 0;
        z-index: 2;
        i {
          color: white !important;
        }
      }
      .mdi-chevron-right-circle {
        right: 20px;
      }
      .mdi-chevron-left-circle {
        left: 20px;
      }
    }
  }
  @media screen and (max-width: 700px) {
    .v-input {
      max-width: initial !important;
    }
  }
}
</style>
