<template>
  <div class="trip-surprise">
    <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
      <div class="text-h6 font-weight-bold">Saudi surprise questions</div>
      <v-card v-if="!data.saudi_surprise_questions.length" flat max-width="410" class="text--text">
        There is no question yet. Please, select question from the list or create new one
      </v-card>
      <div v-else>
        <div class="text--text mb-6">Description</div>
        <draggable :list="data.saudi_surprise_questions" @change="changeOrder($event)" handle=".handle">
          <v-card flat outlined v-for="(i, j) in data.saudi_surprise_questions" :key="i.id" :class="j ? 'mt-7' : ''">
            <div class="primary pa-5 pb-3 question-block">
              <img v-if="role != 'trip_organizer'" class="move-icon mx-auto d-block handle" src="@/assets/icon/move.svg" alt="icon" />
              <div class="d-flex white--text mb-2">
                <div class="text-body-2">
                  <span class="text-capitalize">{{ i.question_type ? i.question_type.split('_')[0] : '' }}</span> select
                </div>
                <v-spacer></v-spacer>
                <v-btn v-if="role != 'trip_organizer'" @click="openDialog(i, j)" x-small icon>
                  <v-icon color="white">mdi-close</v-icon>
                </v-btn>
              </div>
              <div class="font-weight-bold white--text mb-2">Question</div>
              <div class="d-flex flex-md-row flex-column multi-direct">
                <v-text-field
                  class="field46 width100 mb-2 me-md-3"
                  v-model="i.question_en"
                  placeholder="Title"
                  hide-details
                  readonly
                  solo
                  dense
                  required
                  color="primary"
                  height="46"
                ></v-text-field>
                <v-text-field
                  class="field46 width100 mb-2 ms-md-3"
                  v-model="i.question_ar"
                  placeholder="عنوان"
                  hide-details
                  readonly
                  solo
                  dense
                  required
                  color="primary"
                  height="46"
                ></v-text-field>
              </div>
              <div class="d-flex flex-md-row flex-column multi-direct">
                <v-text-field
                  class="field46 width100 mb-2 me-md-3"
                  v-model="i.description_en"
                  placeholder="Short description"
                  hide-details
                  readonly
                  solo
                  dense
                  required
                  color="primary"
                  height="46"
                ></v-text-field>
                <v-text-field
                  class="field46 width100 mb-2 ms-md-3"
                  v-model="i.description_ar"
                  placeholder="وصف قصير"
                  hide-details
                  readonly
                  solo
                  dense
                  required
                  color="primary"
                  height="46"
                ></v-text-field>
              </div>
            </div>
            <div class="pa-5">
              <div class="font-weight-bold">Answers</div>
              <div v-if="i.answer_with_picture" class="d-flex flex-wrap align-center justify-space-between">
                <v-img
                  v-for="(n, k) in i.answers"
                  :key="n.id"
                  cover
                  class="mt-4 rounded"
                  :max-width="imgWidth[i.answers.length - 2][k]"
                  height="200"
                  width="100%"
                  :src="n.picture"
                  alt="picture"
                >
                  <div class="d-flex align-center justify-center shadow">
                    <div class="white--text text-h6 font-weight-bold">{{ n.answer_en }}<br />{{ n.answer_ar }}</div>
                  </div>
                </v-img>
              </div>
              <div v-else v-for="a in i.answers" :key="a.id" class="mt-2 d-flex flex-md-row flex-column multi-direct">
                <v-text-field
                  class="field46 width100 me-md-3"
                  v-model="a.answer_en"
                  placeholder="Title"
                  hide-details
                  readonly
                  outlined
                  dense
                  required
                  color="primary"
                  height="46"
                ></v-text-field>
                <v-text-field
                  class="field46 width100 ms-md-3"
                  v-model="a.answer_ar"
                  placeholder="عنوان"
                  hide-details
                  readonly
                  outlined
                  dense
                  required
                  color="primary"
                  height="46"
                ></v-text-field>
              </div>
            </div>
          </v-card>
        </draggable>
      </div>
      <div class="mt-4" v-if="role != 'trip_organizer'">
        <v-btn @click="modal = true" depressed class="primary rounded me-4" width="136" height="30">
          <span class="text-body-2">Add question</span>
        </v-btn>
        <v-btn
          :to="`/saudi-surprise-create?trip=${$route.params.tripId}`"
          depressed
          color="gray lighten-1"
          class="rounded"
          outlined
          width="136"
          height="30"
        >
          <span class="text-body-2 black--text">Create new</span>
        </v-btn>
      </div>
    </div>
    <v-dialog v-model="dialog" width="460">
      <v-card class="py-5 px-7">
        <div class="font-weight-bold f18 mb-5">Do you really want remove {{ modalData.question_en }}?</div>
        <div class="text-end">
          <v-btn @click="dialog = false" outlined class="rounded" color="gray lighten-1" width="106" height="30">
            <span class="text-body-2 black--text">Cancel</span>
          </v-btn>
          <v-btn @click="removeItem" depressed class="primary rounded ms-3" width="106" height="30">
            <span class="text-body-2">Done</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modal" width="700">
      <v-card>
        <div class="pt-5 pb-3 px-10">
          <div class="font-weight-bold text-center mb-3">Questions</div>
          <v-text-field
            class="field46"
            v-model="search"
            @keyup="searchQuestion"
            prepend-inner-icon="mdi-magnify"
            placeholder="Search by question"
            hide-details
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
        </div>
        <v-card outlined tile class="py-3 px-10 view-without-scroll modal" height="446">
          <v-checkbox
            v-for="n in searchList"
            :key="n.id"
            class="px-4 py-2 radio mt-0 mb-2"
            color="primary"
            v-model="answers"
            :value="n.id"
            hide-details
          >
            <template v-slot:label>
              <div class="ms-2">
                <div class="black--text">{{ n.question_en }}</div>
                <div class="text-body-2">
                  <span class="text-capitalize">{{ n.question_type ? n.question_type.split('_')[0] : '' }}</span> select:
                  {{ n.answers.length }} | Picture {{ n.answer_with_picture ? 'included' : 'not included' }}
                </div>
              </div>
            </template>
          </v-checkbox>
        </v-card>
        <div class="py-5 px-10 text-end">
          <v-btn @click="modal = false" class="rounded" width="106" height="30" outlined color="gray lighten-1">
            <span class="black--text text-body-2">Cancel</span>
          </v-btn>
          <v-btn @click="addQuestion" :disabled="!answers.length" depressed width="106" height="30" class="primary ms-3 rounded">
            <span class="text-body-2">Done</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
  },
  data() {
    return {
      modal: false,
      dialog: false,
      search: '',
      idx: '',
      modalData: {},
      searchList: [],
      answers: [],
      imgWidth: [
        [354, 354],
        [230, 230, 230],
        [354, 354, 354, 354],
        [354, 354, 230, 230, 230],
        [230, 230, 230, 230, 230, 230],
      ],
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getTripSSQ');
  },
  methods: {
    notifi(btn) {
      if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Trip question updated successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'remove') {
        this.$notify({
          title: 'Done!',
          message: 'Trip question remove',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    searchQuestion() {
      const value = this.search.toString().toLowerCase();
      this.searchList = this.questionList.filter((i) => {
        return Object.keys(i).some((k) => {
          return i[k].toString().toLowerCase().indexOf(value) !== -1;
        });
      });
    },
    async addQuestion() {
      await this.$store.dispatch('addTripSSQItem', this.answers).then(() => {
        this.modal = false;
      });
    },
    changeOrder(e) {
      this.updateNumber(e.moved.element.id, e.moved.newIndex);
    },
    async updateNumber(id, order) {
      this.error = [];
      const data = new Object();
      data.order = +order;
      await this.$store.dispatch('orderTripSSQ', { id: id, data: data }).catch((e) => {
        this.error = e.response.data.error;
      });
    },
    async updateOrder(i) {
      this.error = [];
      await this.$store
        .dispatch('updateTripSSQItemOrder', { id: i.id, data: data })
        .then(() => {
          this.notifi('update');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    openDialog(i, j) {
      this.idx = j;
      this.modalData = i;
      this.dialog = true;
    },
    async removeItem() {
      this.error = [];
      await this.$store
        .dispatch('removeTripSSQ', this.modalData.id)
        .then(() => {
          this.dialog = false;
          this.notifi('remove');
          this.data.saudi_surprise_questions.splice(+this.idx, 1);
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  watch: {
    modal() {
      this.searchList = this.questionList;
      this.search = '';
      this.answers = [];
    },
  },
  computed: {
    data() {
      return this.$store.getters.trips;
    },
    questionList() {
      return this.$store.getters.tripsSSQ;
    },
    role() {
      return this.$store.getters.profile.role;
    },
  },
};
</script>

<style lang="scss">
#app .modal .radio {
  display: flex;
  align-items: center;
  min-height: 76px;
  border-radius: 10px;
  border: 1px solid var(--v-gray-lighten2);
  background: initial;
  .v-input--selection-controls__input {
    margin-top: 2px;
  }
}
#app .modal .radio.v-input--is-label-active,
#app .modal .radio.v-item--active {
  border: 1px solid var(--v-primary-base);
  background: #deeaf7;
}
.trip-surprise {
  .move-icon {
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    text-align: center;
    filter: brightness(150%);
  }
  .shadow {
    height: 100%;
    background: rgba(51, 51, 51, 0.24);
  }
  .question-block {
    .v-input__slot {
      background: white;
    }
  }
}
</style>
