<template>
  <div class="trip-act">
    <div v-for="(item, j) in data" :key="item.id">
      <v-divider v-if="j"></v-divider>
      <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
        <div class="text-h6 font-weight-bold mb-2">{{ item.title_en }}</div>
        <div class="d-flex flex-wrap align-center">
          <v-chip
            outlined
            v-for="i in item.filters"
            :key="i.id"
            :class="trip.filters.some((j) => j.id == i.id) ? 'primary lighten-1 primary--text' : 'text--text'"
            class="text-body-1 me-4 my-2"
            @click="updateFilter(i.id)"
            style="height: 38px"
          >
            <v-icon v-if="trip.filters.find((j) => j.id == i.id)" color="primary" class="ms-1 me-2">mdi-check mdi-24px</v-icon>
            {{ i.title_en }} <span class="px-2 gray--text text--lighten-3">|</span> {{ i.title_ar }}
          </v-chip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getTripsGroupsList', '?active=true');
  },
  methods: {
    notifi(btn) {
      if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Trip filters updated successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async updateFilter(d) {
      const data = new Object();
      data.trips_group_filters = [];
      if (this.trip.filters.find((j) => j.id == d)) {
        const item = this.trip.filters.find((j) => j.id == d);
        const idx = this.trip.filters.indexOf(item);
        this.trip.filters.splice(idx, 1);
        for (let i = 0; i < this.trip.filters.length; i++) {
          data.trips_group_filters.push(this.trip.filters[i].id);
        }
      } else {
        this.trip.filters.push({ id: d });
        for (let i = 0; i < this.trip.filters.length; i++) {
          data.trips_group_filters.push(this.trip.filters[i].id);
        }
      }
      await this.$store.dispatch('postFiltersTripsItem', data);
      this.notifi('update');
    },
  },
  computed: {
    data() {
      return this.$store.getters.tripsGroupsList;
    },
    trip() {
      return this.$store.getters.trips;
    },
  },
  destroyed() {
    this.$store.dispatch('setTripsGroupsList');
  },
};
</script>

<style lang="scss">
.trip-act {
  .v-btn {
    border-radius: 4px;
  }
}
</style>
